import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { HeaderComponent } from '../../components/header/header.component';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
    selector: 'yfs-area',
    templateUrl: './area.component.html',
    styleUrls: ['./area.component.scss'],
    standalone: true,
    imports: [HeaderComponent, QRCodeModule]
})
export class AreaComponent implements OnInit {

  constructor (public dialog: MatDialog) {}

  ngOnInit() {
    if (localStorage.getItem("visibleModalUnauthorized") === 'open') {
      this.openModalUnauthorized()
    }
  }

  openModalUnauthorized() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Não é possível visualizar essas informações pois você não tem permissões para acessar essa tela.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    }).afterClosed().subscribe(() => this.closeModalHandle())
  }

  closeModalHandle() {
    localStorage.setItem("visibleModalUnauthorized", 'close')
  }

}
