import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';
import { HeaderComponent } from '../../components/header/header.component';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { MatCard } from '@angular/material/card';
import { HasAuthorizationDirective } from '../../shared/directives/has-authorization.directive';

@Component({
    selector: 'yfs-parameters',
    templateUrl: './parameters.component.html',
    styleUrls: ['./parameters.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LinkBackComponent, MatCard, HasAuthorizationDirective]
})
export class ParametersComponent {
  public comunicationAllowedPermissions = [EPermissaoAcesso.CADASTRAR_MENSAGEM]
  public parameterTariffAllowedPermissions = [EPermissaoAcesso.LISTAR_PARAMETROS]
  public tariffHistoricAllowedPermissions = [EPermissaoAcesso.HISTORICO_PARAMETROS]
  public productsAllowedPermissions = [EPermissaoAcesso.LISTAR_PRODUTOS]
  public concessionairesAndPartnersAllowedPermissions = [EPermissaoAcesso.CREDENCIAMENTO_CONCESSIONARIA]
  public generateChangeReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_MUDANCA_STATUS]
  public manageMinutaAllowedPermissions = [EPermissaoAcesso.GERENCIAR_MINUTA]
  public consultDealershipAlowedPermissions = [EPermissaoAcesso.CONSULTAR_CONCESSIONARIA]
  public previousScreen: string = '';

  constructor(private router: Router) {}

  goToComunication() {
    this.previousScreen = 'parameters';
    this.router.navigate(["communication"], { state: { previousScreen: 'parameters' } });
  }

  goToProducts() {
    this.previousScreen = 'parameters';
    this.router.navigate(["products"], { state: { previousScreen: 'parameters' } });
  }

  goToParameterTariff() {
    this.previousScreen = 'parameters';
    this.router.navigate(["parameter-tariff"], { state: { previousScreen: 'parameters' } });
  }

  goToParameterCommission() {
    this.previousScreen = 'parameters';
    this.router.navigate(["parameter-commission"], { state: { previousScreen: 'parameters' } });
  }

  goToOldTablesTariff() {
    this.previousScreen = 'parameters';
    this.router.navigate(["old-tables-tariff"], { state: { previousScreen: 'parameters' } });
  }

  goToOldTablesCommissions() {
    this.previousScreen = 'parameters';
    this.router.navigate(["old-tables-commissions"], { state: { previousScreen: 'parameters' } });
  }

  goToConcessionairesAndPartners() {
    this.previousScreen = 'parameters';
    this.router.navigate(["concessionaires-and-partners"], { state: { previousScreen: 'parameters' } })
  }

  goToConcessionairesAndPartnersB2b2c() {
    this.previousScreen = 'parameters';
    this.router.navigate(["concessionaires-and-partners-b2b2c"], { state: { previousScreen: 'parameters' } })
  }

  goToChangeReport() {
    this.previousScreen = 'parameters';
    this.router.navigate(["change-report"], { state: { previousScreen: 'parameters' } })
  }

  goToDraftList() {
    this.previousScreen = 'parameters';
    this.router.navigate(["draft-list"], { state: { previousScreen: 'parameters' } })
  }

  getBack() {
    this.router.navigate(['area']);
  }
}
