<yfs-header></yfs-header>
<section class="container-content">
    <div class="button-back">
      <yfs-link-back [text]="previousScreen === 'parameters' ? 'Voltar para parâmetros técnicos' : 'Voltar para minha área'" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
      <h1>LISTA DE CONCESSIONÁRIAS E PARCEIROS</h1>
      <yfs-button *appHasAuthorization="hasConcessionaire" state="enabled" label="CREDENCIAR EMPRESA" type="primary" (click)="openModalAccreditation()"></yfs-button>
  </div>
    <mat-card>
        <mat-card-content>
            <div *ngIf="inative > 0" class="alert-box">
                <mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols" fontIcon="info"></mat-icon>
                <span>Existem {{inative}} concessionárias inativas com planos em andamento para serem transferidos.</span>
            </div>
          <div class="inputs-content">
            <mat-form-field id="name" style="flex: 2" appearance="fill">
              <mat-label>Busque por nome, E-mail ou CNPJ</mat-label>
              <mat-icon aria-hidden="false" aria-label="Ícone de busca"
                class="search-icon material-symbols-outlined" (click)="search()" fontIcon="search"></mat-icon>
              <input [formControl]="searchString" matInput (keyup.enter)="search()" (keydown.tab)="search()">
            </mat-form-field>

            <mat-form-field id="group" appearance="fill">
              <mat-label>Filtrar por grupo financeiro</mat-label>
              <mat-select #mySel [formControl]="grupoFinanceiro" multiple>
                <div class="select-all" (click)="selectAllFinancialGroup()">
                  <mat-checkbox [(ngModel)]="checkedAllGroup"
                    [indeterminate]="selectedGroup.length > 0 && selectedGroup.length < listaGrupoFinanceiro.length"
                    color="primary"><span class="select-label">Todos</span></mat-checkbox>
                </div>
                <mat-option *ngFor="let grupo of listaGrupoFinanceiro" [value]="grupo.codigoGrupo"
                  (click)="checkHandleFinancialGroup(grupo.codigoGrupo); search()"><span class="select-label">{{ grupo.nomeGrupo }}</span></mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Filtrar por tipo</mat-label>
              <mat-select #mySel [formControl]="tipo" multiple>
                <div class="select-all" (click)="selectAllTipo()">
                  <mat-checkbox [(ngModel)]="checkedAllTipo"
                    [indeterminate]="selectedGroup.length > 0 && selectedGroup.length < listaTipo.length"
                    color="primary"><span class="select-label">Todos</span></mat-checkbox>
                </div>
                <mat-option *ngFor="let tipo of listaTipo" [value]="tipo.IdTipo"
                  (click)="checkHandleTipo(tipo.IdTipo); search()"><span class="select-label">{{ tipo.Nome }}</span></mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Filtrar por status</mat-label>
              <mat-select #mySel [formControl]="status" multiple>
                <div class="select-all" (click)="selectAllStatus()">
                  <mat-checkbox [(ngModel)]="checkedAllStatus"
                    [indeterminate]="selectedStatus.length > 0 && selectedStatus.length < listaStatus.length"
                    color="primary"><span class="select-label">Todos</span></mat-checkbox>
                </div>
                <mat-option *ngFor="let status of listaStatus" [value]="status.IdStatus"
                  (click)="checkHandleStatus(status.IdStatus); search()"><span class="select-label">{{ status.Nome }}</span></mat-option>
              </mat-select>
            </mat-form-field>
          </div>
              <div>
                <table *ngIf="hasValue" mat-table [dataSource]="dataSource" #firstTable #firstTableSort="matSort"
                    matSort (matSortChange)="announceSortChange($event)">

                    <ng-container matColumnDef="codigo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                            Código
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.codigo}} </td>
                    </ng-container>

                    <ng-container matColumnDef="razaoSocial">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by razaoSocial">
                            Concessionária
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="flex-column">
                                {{element.razaoSocial}}
                                <strong>{{element.cnpj | cnpj}}</strong>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="grupoFinanceiroNome">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by grupoFinanceiroNome">
                            Grupo financeiro
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="!element.grupoFinanceiroNome">-</div>
                            <div *ngIf="element.grupoFinanceiroNome" class="flex-column">
                                {{element.grupoFinanceiroNome}}
                                <strong>{{element.grupoFinanceiroCodigo}}</strong>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by tipo">
                            Tipo
                        </th>
                        <td mat-cell *matCellDef="let element">{{getTipoDescription(element.tipo)}}</td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="status-container">
                                <mat-icon aria-hidden="false" aria-label="Ícone" class="material-symbols-outlined"
                                    [fontIcon]="getStatusIcon(element.status)"
                                    [style.color]="getStatusColor(element.status)"></mat-icon>
                                {{getStatusDescription(element.status)}}
                                <div style="height: 24px;">
                                    <yfs-tooltip  [tooltipText]="getTextToToolTip(element)">
                                        <ng-container *ngIf="element.status == 2">
                                            <mat-icon
                                                aria-hidden="false"
                                                style="color: var(--color-functional-warning-1); cursor: pointer;"
                                                aria-label="Ícone de informação"
                                                class="material-symbols"
                                                fontIcon="info">
                                            </mat-icon>
                                        </ng-container>
                                    </yfs-tooltip>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="acoes">
                        <th mat-header-cell *matHeaderCellDef>
                            Ações
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div id="action-buttons-container">
                              <yfs-button *ngIf="element.status == 1 || element.status == 3" state="enabled" label="DESCREDENCIAR" type="secondary" size="small" icon="cancel" color="red" [fullWidth]="true" (click)="verifyPlan(element, 'decertify')"></yfs-button>
                              <yfs-button *ngIf="(element.status == 4 || element.status == 5) && element.hasContract === true" state="enabled" label="TRANSFERIR PLANOS" type="secondary" size="small" icon="cancel" color="red" [fullWidth]="true" (click)="transfer(element)"></yfs-button>
                          </div>
                      </td>
                   </ng-container>

                   <ng-container matColumnDef="qrcode">
                    <th mat-header-cell *matHeaderCellDef>
                        QR Code
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-icon (click)="openQrCodeModal(element.hashCode, element.razaoSocial)">qr_code</mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'transferir-planos-row': (row.status == 4 || row.status == 5) && row.hasContract === true}"></tr>
                </table>
                <div *ngIf="!hasValue" class="content-empty">
                    <img src="assets/svg/empty-document.svg" alt="documento vazio" height="64" width="64">
                    <div>
                      <span>Nenhum resultado encontrado.</span>
                      <span>Tente novamente com outros filtros.</span>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="paginator-content">
        <mat-paginator [pageIndex]="pageIndex" [length]="length" [pageSize]="pageSize" aria-label="Select page"
            [pageSizeOptions]="pageSizeOptions" (page)="handlePageEvent($event)">
        </mat-paginator>
    </div>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
