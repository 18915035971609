import { Component, OnInit, Output, EventEmitter, ViewChild, Input, HostListener } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { dateFormat } from 'src/app/shared/date-format';
import tinymce, { Editor, EditorOptions } from 'tinymce/tinymce';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { EditorComponent, EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ModalPreviewDraftComponent } from 'src/app/components/modal-preview-draft/modal-preview-draft.component';
import { LinkBackComponent } from '../../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { MatFormField, MatLabel, MatError, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { NgxMaskDirective } from 'ngx-mask';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';

@Component({
    selector: 'yfs-new-draft',
    templateUrl: './new-draft.component.html',
    styleUrls: ['./new-draft.component.scss'],
    standalone: true,
    imports: [LinkBackComponent, EditorModule, MatCard, MatCardContent, NgIf, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatError, MatSelect, NgFor, MatOption, MatDatepickerInput, NgxMaskDirective, MatDatepickerToggle, MatSuffix, MatDatepicker, MatIcon, NgClass, ButtonComponent, LoadingSpinnerComponent, DatePipe],
    providers: [
      { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ],
  })
export class NewDraftComponent implements OnInit {
  @Output() currentFlowChanged = new EventEmitter<string>();
  @ViewChild(EditorComponent) editor: EditorComponent | undefined;

  public nomenclatura = new FormControl('', [Validators.required]);
  public tipoMinuta = new FormControl('', [Validators.required]);
  public dataInicial = new FormControl('', [Validators.required, dateFormat.parameterTariffValidator]);
  public dataInicialLabel: Date | undefined;
  public minDate: Date | undefined;
  public dateError = false;
  public tinymeceSettings: any
  public isLoading = false;
  public listWildCards: any
  public filtedListWildCards: any;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public genericMenssage = "Campo obrigatório";
  public typeErrorMessage = '';
  public mensagemInvalid = false;
  public previewContent: any
  public tipoMinutaList: any

  public fileSizeError: boolean = false;
  public srcResult: any;
  public fileName: any;
  public fileHtml: string | undefined;
  public invalidFormat = false;
  public search = "";
  public isFullWidth = window.innerWidth < 1279;
  public editorContent: string = '';
  public isButtonEnabled = false;
  public apiKey: string = 'p77aqsc2i9zhfby8jfovd7rz7fkc8azlqdsbpuzs96abhe5x';
  public init: Partial<EditorOptions> = {
    plugins: [
      'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
      'fullscreen', 'preview', 'visualchars', 'code'
    ],
    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | preview fullscreen code',
    menu: {
      view: { title: 'View', items: 'code preview fullscreen visualchars visualaid' }
    },
    setup: (editor) => {
      editor.on('init keyup change', () => {
        this.highlightWildcards();
        const content = editor.getContent();
      this.isButtonEnabled = content.trim().length > 0;
      });
    },
    height: 800,
    tinycomments_mode: 'embedded',
    tinycomments_author: 'Author name',
    base_url: '/tinymce',
    suffix: '.min',
    valid_elements: '*[*]',
    extended_valid_elements: '*[*]',
    forced_root_block: '',
    verify_html: false,
    cleanup: false,
    remove_trailing_brs: false,
    convert_urls: false,
    entity_encoding: 'raw',
  }
    editorInstance: Editor | undefined;


  constructor(private apiService: ApiHttpService, public dialog: MatDialog) {
    const currentDate = new Date();
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(currentDate.getDate() + 1);
    this.minDate = tomorrowDate;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 1279;
  }

  ngOnInit() {
    this.getWildCards();
    this.getTipoMinutaList();
  }

  getBack() {
    this.currentFlowChanged.emit('listDraft');
  }

  onEditorInit(event: any) {
    this.editorInstance = event.editor;
    this.highlightWildcards();
  }

  highlightWildcards() {
    if (this.editorInstance) {
      const content = this.editorInstance.getContent();
      this.filtedListWildCards?.forEach((wildcard: any) => {
        wildcard.isIdentified = content.includes(wildcard.tag);
      });
    }
  }

  insertWildcard(wildcardTag: string) {
    if (this.editorInstance) {
      this.editorInstance.execCommand('mceInsertContent', false, wildcardTag);
    }
  }

  onInputChangeInicial(isCalendar: boolean) {
    if (this.dataInicial.value?.length === 0) {
      this.dataInicialLabel = undefined;
    }

    if (this.dataInicial && this.dataInicial.value?.replace(/[^a-zA-Z0-9]/g, '').length === 8) {
      const dataInicial2 = this.dataInicial.value ?
        this.getDateByString(this.dataInicial.value) : null;

      if (!isCalendar) {
        this.dataInicialLabel = dataInicial2 || undefined
      }
    }
  }

  getDateByString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  getWildCards() {
    this.isLoading = true;
    this.apiService.getWildcards().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          if (result) {
            this.listWildCards = result.wildcards;
            this.filtedListWildCards = result.wildcards;
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  getTipoMinutaList() {
    this.isLoading = true;
    this.apiService.getTipoMinuta().pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.tipoMinutaList = result;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  convertDateToString(data: any) {
    const dataOriginal = new Date(data);
    const dia = dataOriginal.getUTCDate();
    const mes = dataOriginal.getUTCMonth() + 1;
    const ano = dataOriginal.getUTCFullYear();
    return `${dia.toString().padStart(2, '0')}/${mes.toString().padStart(2, '0')}/${ano.toString()}`;
  }

  convertDataLabel(dateString: any) {
    const [dayStr, monthStr, yearStr] = dateString.split('/');
    const day = parseInt(dayStr);
    const month = parseInt(monthStr) - 1;
    const year = parseInt(yearStr);
    const dateObj = new Date(year, month, day);
    return dateObj
  }

  convertStringToDate(dateString: any) {
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString();
  }


  handleSaveButtonClick = () => {
    this.nomenclatura.markAllAsTouched();
    this.tipoMinuta.markAllAsTouched();
    this.dataInicial.markAllAsTouched();

    const mensagem = `<meta charset="utf-8">` + (this.editorInstance?.getContent() || '');

    if (mensagem.length === 0) {
      this.mensagemInvalid = true;
      this.typeErrorMessage = 'required';
    } else {
      this.mensagemInvalid = false;
      this.typeErrorMessage = '';
    }

    const isValidFields = this.nomenclatura.valid && this.tipoMinuta.valid && this.dataInicial.valid && !this.mensagemInvalid;

    if (isValidFields) {
      const newTemplate = {
        nomenclatura: this.nomenclatura.value,
        idTipoMinuta: this.tipoMinuta.value,
        dataInicioVigencia: this.dataInicial.value ? this.convertStringToDate(this.dataInicial.value) : undefined,
        template: mensagem
      };
      this.postNewMinuta(newTemplate);
    }
  };

  isEnbledButton() {
    const editorContent = this.editorInstance?.getContent() || '';
    return this.dataInicial.valid && this.nomenclatura.valid && this.tipoMinuta.valid && editorContent.length > 0;
  }

  postNewMinuta(newMinuta: any) {
    this.isLoading = true;

    this.apiService.postNewMinutaTemplate(newMinuta).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            () => 'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        return throwError(() => error);
      })
    )
      .subscribe({
        next: (result) => {
          this.isLoading = false;
          this.openModalSucess()
        },
        error: (error) => {
          this.isLoading = false;
          if (error?.error?.Errors && Array.isArray(error.error.Errors) && error.error.Errors.length > 0) {
            this.isLoading = false;
            this.openModalErrorGeneric(error.error.Errors[0]);
          } else this.openModalErrorNewTemplate()
          console.log(error);
        },
      });
  }

  getConfigModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.width = '384px'
    return dialogConfig;
  }

  openModalSucess() {
    const dialogConfig = this.getConfigModal()
    this.dialog.open(ModalGenericComponent, {
      ...dialogConfig,
      data: {
        icon: 'success',
        text: 'Minuta criada com sucesso.',
        primaryButtonAction: () => { this.getBack(), this.dialog.closeAll() },
        primaryButtonText: 'IR PARA LISTA DE MINUTAS',
        primaryButtonVariant: 'primary',
      }
    })
  }

  cancelButtonHandle() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Você tem certeza de que deseja sair sem salvar as alterações? Todas as modificações feitas serão perdidas.',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR E EDITAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.getBack(), this.dialog.closeAll() },
        secundaryButtonText: 'SIM, SAIR',
        secundaryButtonVariant: 'primary',
      }
    })
  }

  openModalErrorNewTemplate() {
    const dialogConfig = this.getConfigModal()
    this.dialog.open(ModalGenericComponent, {
      ...dialogConfig,
      data: {
        icon: 'error',
        text: 'Não foi possível cadastrar a minuta nesse momento. Tente novamente mais tarde.',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => { this.handleSaveButtonClick(); this.dialog.closeAll() },
        secundaryButtonText: 'TENTAR NOVAMENTE',
        secundaryButtonVariant: 'primary'
      }
    })
  }

  openModalErrorGeneric(message: string) {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'error',
        text: message,
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openModalPreview = () => {
    const editor = tinymce?.activeEditor;

    if (editor) {
      const editorContent = editor.getContent();

      this.dialog.open(ModalPreviewDraftComponent, {
        height: '800px',
        width: '90vw',
        panelClass: 'preview-draft-dialog',
        data: {
          previewContent: editorContent,
          nomenclatura: this.nomenclatura.value,
          dataVigenciaInicial: this.dataInicial.value,
          tipoMinuta: this.tipoMinuta.value,
          listWildCards: this.listWildCards,
        }
      });
    } else {
      console.error('TinyMCE editor instance is not available');
    }
  };

  openModalPdf = () => {
    const editor = tinymce?.activeEditor;

    if (editor) {
      let editorContent = editor.getContent();
      editorContent = `<meta charset="utf-8">\n` + editorContent;
      this.isLoading = true;

      this.apiService.convertHtmlToPdf(editorContent).subscribe(
        (response) => {
          this.isLoading = false;

          const pdfBase64 = `data:application/pdf;base64,${response.pdfBase64}`;
          this.dialog.open(ModalPreviewDraftComponent, {
            height: '800px',
            width: '90vw',
            panelClass: 'preview-draft-dialog',
            data: {
              previewContent: editorContent,
              nomenclatura: this.nomenclatura.value,
              dataVigenciaInicial: this.dataInicial.value,
              tipoMinuta: this.tipoMinuta.value,
              listWildCards: this.listWildCards,
              pdf: pdfBase64,
            },
          });
        },
        (error) => {
          this.isLoading = false;
          console.error('Erro ao converter HTML para PDF:', error);
        }
      );
    } else {
      console.error('TinyMCE editor instance is not available');
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    const allowedTypes = ['text/html'];

    if (file) {
      if (!allowedTypes.includes(file.type))
        {
          this.invalidFormat = true;
          return;
      }
      this.invalidFormat = false;
      const reader = new FileReader();

      reader.onload = () => {
        const htmlString: string = reader.result as string;
        this.fileHtml = htmlString;
        this.fileName = file.name;
        this.previewContent = this.fileHtml;
      };

      reader.readAsText(file);
    }
  }

  onRemoveFile() {
    this.fileName = null;
    const inputNode = document.querySelector('input[type="file"]') as HTMLInputElement;
    inputNode.value = null ?? '';
    this.fileSizeError = false;
    this.fileHtml = '';
    this.previewContent = ''
  }

  applyFilter() {
      const cleanedInput = this.search.toLowerCase().replace(/[%\[\]]/g, '');
      this.filtedListWildCards = this.listWildCards.filter((tagObj: { tag: string; }) => {
      const cleanedTag = tagObj.tag.toLowerCase().replace(/[%\[\]]/g, '');
      return cleanedTag.includes(cleanedInput);
    });
  }

}
