import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { TimeoutError, catchError, throwError, timeout } from 'rxjs';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { ModalInformationComponent } from 'src/app/components/modal-information/modal-information.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { NgIf, NgClass, CurrencyPipe, DatePipe } from '@angular/common';
import { LinkBackComponent } from '../../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';


export interface TipoTarifaOption {
  label: string;
  value: string;
}

@Component({
    selector: 'yfs-refund-rate',
    templateUrl: './refund-rate.component.html',
    styleUrls: ['./refund-rate.component.scss'],
    standalone: true,
    imports: [NgIf, LinkBackComponent, MatCard, MatCardContent, MatIcon, ButtonComponent, MatTable, MatSort, NgClass, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatCheckbox, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, LoadingSpinnerComponent, CurrencyPipe, DatePipe]
})
export class RefundRateComponent implements OnInit{
  @Input() idContrato: number | undefined;
  @Input() numeroContrato: string | undefined;
  @Input() dadosBancarios: any;
  @Input() isRefundRateFlowOk: boolean | undefined;
  @Output() currentFlowChanged = new EventEmitter<string>();
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @HostListener('window:scroll')
  @HostListener('scroll', ['$event'])

  displayedData: any;
  displayedDataRefund: any;
  itemsPerLoad = 20;
  loadedItems = 0;

  public currentFlow = 'releaseValues';
  public pageIndex = 0;
  public isLoading = false;
  public tipoTarifaControl = '';
  public tipoTarifa = new FormControl('');
  public convertBase64 = 'data:image/png;base64,';
  public awaitData = true;
  public canceladoAntesDosSeteDias = false;
  public produto: any;
  public dataSource: any;
  public contratosTarifas: any;
  public selectedValue: any;
  public idsContratoTarifas: number[] = [];
  public tarifasSelecionadas: any[] = [];
  public valorAcumulado: number = 0;
  public isCleanDisabled = true;
  public showRefundTable: number = 0;
  public showHistoric: number = 0;
  public isFullWidth = window.innerWidth < 800;

  displayedColumns: string[] = [
    'selecionar',
    'tipo',
    'descricaoTarifa',
    'dataCadastro',
    'valorTarifa',
  ];

  displayedColumnsRefund: string[] = [
    'numeroRequisicao',
    'tarifas',
    'mensagemErro',
    'dataCadastro',
    'valorRequisicao',
    'download'
  ];

  constructor(
    public dialog: MatDialog,
    private apiService: ApiHttpService,
    private _liveAnnouncer: LiveAnnouncer,
    private el: ElementRef,
    private cdref: ChangeDetectorRef,
    ){}

  @ViewChild('firstTableSort')
  firstTableSort!: MatSort;

  @ViewChild('secondTableSort')
  secondTableSort!: MatSort;

  ngOnInit() {
    this.sendRequest();
    this.loadMoreItems();
    this.ajustarPosicaoFlutuante();
  }

  ngAfterViewInit() {
    if (this.displayedData) {
      this.displayedData.sort = this.firstTableSort;
    }
  }


  ajustarPosicaoFlutuante() {
    const scrollContainerElement = this.el.nativeElement.querySelector('#scrollContainer');
    const flutuanteCardElement = this.el.nativeElement.querySelector('.flutuante-card');

    if (scrollContainerElement && flutuanteCardElement) {
      const scrollContainerTop = scrollContainerElement.getBoundingClientRect().top;
      flutuanteCardElement.style.top = `${scrollContainerTop}px`;
    }
  }

  loadMoreItems() {
    const remainingData = this.displayedData?.slice(this.loadedItems, this.loadedItems + this.itemsPerLoad);
    this.displayedData = this.displayedData?.concat(remainingData);
    this.loadedItems += this.itemsPerLoad;
  }

  onScroll(event: any) {
    const element = event.target;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
      this.loadMoreItems();
    }
  }

  openModalNoContent() {
    this.dialog.open(ModalGenericComponent, {
      width: '400px',
      data: {
        text: 'Não há tarifas elegíveis para devolução no momento.',
        primaryButtonAction: () => {
          this.getBack();
          this.dialog.closeAll();
        },
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
        icon: 'info',
      },
    });
  }

  openModalComplete = () => {
    this.dialog.open(ModalGenericComponent, {
      width: '400px',
      data: {
        text: 'Tem certeza que deseja concluir a devolução?',
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonAction: () => {
          this.completeRelease();
        },
        secundaryButtonText: 'SIM, CONCLUIR DEVOLUÇÃO',
        secundaryButtonVariant: 'primary',
      },
    });
  }

  openModalSuccess() {
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '400px',
      data: {
        text: 'A devolução foi feita com sucesso.',
        primaryButtonAction: () => {
          this.getBack();
          dialogRef.close();
        },
        primaryButtonText: 'IR PARA PLANO DO CLIENTE',
        primaryButtonVariant: 'primary',
        icon: 'success',
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getBack();
    });
  }

  openModalError() {
    this.dialog.open(ModalGenericComponent, {
      width: '400px',
      data: {
        text: 'Não foi possível concluir a devolução. Tente novamente mais tarde.',
        primaryButtonAction: () => {
          this.getBack();
          this.dialog.closeAll();
        },
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
        icon: 'error',
      },
    });
  }

  openTrySuccess(){
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      width: '384px',
      disableClose: true,
      data: {
        icon: 'success',
        text: `Solicitação de devolução de tarifa(s) encaminhada para tesouraria com sucesso.`,
        primaryButtonAction: () => {
          dialogRef.close();
          this.sendRequest();
        },
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  openTryError() {
    this.dialog.open(ModalGenericComponent, {
      width: '400px',
      data: {
        text: 'Não foi possível encaminhar uma nova solicitação de devolução de tarifa(s) para tesouraria. Por favor, tente novamente mais tarde.',
        primaryButtonAction: () => {
          this.dialog.closeAll();
        },
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
        icon: 'error',
      },
    });
  }

  selectRow(tarifa: any, event: any) {
    const index = this.tarifasSelecionadas.findIndex(
      selectedTarifa => selectedTarifa.idContratoTarifa === tarifa.idContratoTarifa
    );

    if (event.checked) {
      this.tarifasSelecionadas.push(tarifa);
      this.valorAcumulado += tarifa.valorTarifa;
      this.idsContratoTarifas.push(tarifa.idContratoTarifa)
    } else {
      if (index !== -1) {
        this.tarifasSelecionadas.splice(index, 1);
        this.valorAcumulado -= tarifa.valorTarifa;
        this.idsContratoTarifas.splice(index, 1);
      }
    }

    this.isCleanDisabled = this.tarifasSelecionadas.length <= 0;
  }

  completeRelease() {
    const numeroContrato = this.numeroContrato || null;
    this.isLoading = true;
    this.apiService
      .postRefundRate(numeroContrato, this.idsContratoTarifas)
      .pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if(error.status != 500){
            this.openModalError();
            this.isLoading = false;
          }
          return throwError(error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.status === 201) {
            this.openModalSuccess();
            this.isLoading = false;
          } else if (result.status === 204) {
            this.openModalError();
            this.isLoading = false;
          } else if (result.status === 500) {
            this.openModalError();
            this.isLoading = false;
          }
          else {
            this.openModalError();
            this.isLoading = false;
          }
        },
      });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getIcon(status: number) {
    switch (status) {
      case 0:
        return "nest_clock_farsight_analog";
      case 1:
        return "check_circle";
      case 3:
        return "warning";
      case 9:
        return "attach_money"
      case 4:
        return "cancel"
      case 5:
        return "check_circle"
      case 7:
        return "check_circle"
      case 8:
        return "block"
      case 10:
        return "check_circle"
      default:
        return ""
    }
  }

  getIconColor(status: number) {
    if (status === 0 || status === 10) return "var(--color-complementary-yellow-1)"
    if (status === 1) return "var(--color-primary-2)"
    if (status === 3) return "var(--color-functional-warning-1)"
    return "var(--color-neutral-gray-4)"
  }

  getStatusDescription(status: number) {
    switch (status) {
      case 0:
        return "Em Aberto";
      case 1:
        return "Ativo";
      case 3:
        return "Inelegível";
      case 9:
        return "Estorno finalizado"
      case 4:
        return "Cancelado"
      case 5:
        return "Desistência finalizado"
      case 7:
        return "Finalizado"
      case 8:
        return "Inativo"
      case 10:
        return "Desistência em andamento"
      default:
        return ""
    }
  }

  getNome(valor: string) {
    return valor.split('(')[0].trim();
  }

  getCnpj(valor: string) {
    const match = valor?.match(/\(([^)]+)\)/);
    if (match) {
      const cnpjDigits = match[1];
      const cnpjWithMask = cnpjDigits.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5'
      );
      return '(' + cnpjWithMask + ')';
    }
    return '';
  }

  cpfMask(cpf: string){
    const cleanedCpf = cpf.replace(/\D/g, '');
    const cpfWithMask = cleanedCpf.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      '$1.$2.$3-$4'
    );
  return cpfWithMask;
  }

  cleanSelection() {
    this.tarifasSelecionadas = [];
    this.valorAcumulado = 0;
    this.isCleanDisabled = true;
  }

  sendRequest() {
    const idContrato = this.idContrato || null;
    this.isLoading = true;

    this.apiService
      .getRefundRate(idContrato)
      .pipe(
        timeout(300000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(
              'A requisição demorou muito tempo e foi cancelada.'
            );
          }
          if(error.status != 500){
            this.openModalError();
            this.isLoading = false;
          }
          return throwError(error);
        })
      )
      .subscribe({
        next: (result) => {
          if (result.status === 200) {
            this.dataSource = result.body;
            this.produto = result.body.produto;

            this.displayedData = new MatTableDataSource<any>(this.dataSource.contratosTarifas);

            setTimeout(() => {
              this.displayedData.sort = this.firstTableSort;
            });

            this.displayedData.sortingDataAccessor = (item: any, property: any) => {
              switch (property) {
                case 'dataCadastro':
                  return new Date(item.dataCadastro);
                default:
                  return item[property];
              }
            };

            this.displayedDataRefund = new MatTableDataSource<any>(this.dataSource.liberacoesValoresCancelados);

            setTimeout(() => {
              this.displayedDataRefund.sort = this.secondTableSort;
            });

            this.showRefundTable = + (this.dataSource.liberacoesValoresCancelados.length > 0);
            this.showHistoric = + (this.dataSource.contratosTarifas.length > 0);
          } else if (result.status === 204) {
            this.openModalNoContent();
            this.isLoading = false;
          } else {
            this.openModalError();
            this.isLoading = false;
          }
        },
        error: (error) => {
          this.openModalError();
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  tryAgain(id: any){
    this.isLoading = true;
    this.apiService
    .refundRate(id)
    .pipe(
      timeout(300000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(
            'A requisição demorou muito tempo e foi cancelada.'
          );
        }
        if(error.status != 500){
          this.openTryError();
          this.isLoading = false;
        }
        return throwError(error);
      })
    )
    .subscribe({
      next: (result) => {
        if(result.status === 204){
          this.isLoading = false;
          this.openTrySuccess();
        }
      }
    })
  }

  getBack() {
    this.currentFlowChanged.emit('close');
  }

  openBankEdit() {
    this.currentFlowChanged.emit('bankFlow');
  }

  showMessage() {
    if (this.isRefundRateFlowOk || this.dadosBancarios) return false;
    return true
  }

  openTarifaDetails(tarifa: any) {
    this.dialog.open(ModalInformationComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        values: tarifa
      }
    })
  }

}
