import { MatTooltip } from '@angular/material/tooltip';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QRCodeModule } from 'angularx-qrcode';
import { ButtonComponent } from '../buttons/button/button.component';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
  selector: 'yfs-modal-qr-code',
  standalone: true,
  imports: [QRCodeModule, ButtonComponent, MatIcon, MatTooltip, NgIf],
  templateUrl: './modal-qr-code.component.html',
  styleUrl: './modal-qr-code.component.scss'
})
export class ModalQrCodeComponent {
  copiedMessage: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalQrCodeComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { link: string, razaoSocial: string }
  ) {}

  get link(): string {
    return this.data.link;
  }

  get razaoSocial(): string {
    return this.data.razaoSocial;
  }

  copyLink(): void {
    navigator.clipboard.writeText(this.link).then(() => {
      this.copiedMessage = true;
      this.cdr.detectChanges();

      setTimeout(() => {
        this.copiedMessage = false;
        this.cdr.detectChanges();
      }, 8000);

    }).catch(err => {
      console.error('Erro ao copiar o link', err);
    });
  }

  downloadQRCode(): void {
    const qrCodeElement = document.querySelector('qrcode canvas') as HTMLCanvasElement;
    if (qrCodeElement) {
      const img = qrCodeElement.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = img;
      link.download = 'qrcode.png';
      link.click();
    }
  }

  closeModal(): void {
    this.copiedMessage = false;
    this.dialogRef.close();
  }
}
