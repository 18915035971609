import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContractedPlansComponent } from './pages/contracted-plans/contracted-plans.component';
import { HomeComponent } from './pages/home/home.component';
import { RegisterComponent } from './pages/register/register.component';
import { SimulationComponent } from './pages/simulation/simulation.component';
import { AccountingComponent } from './pages/accounting/accounting.component';
import { CommissionsComponent } from './pages/commissions/commissions.component';
import { AuthGuard } from './helpers/guards/auth.guard';
import { AuthorizationGuard } from './helpers/guards/authorization.guard';
import { EPermissaoAcesso } from './shared/enums/permissao-acesso.enum';
import { AreaComponent } from './pages/area/area.component';
import { PositionReportComponent } from './pages/position-report/position-report.component';
import { ContractExtractReportComponent } from './pages/contract-extract-report/contract-extract-report.component';
import { ReleaseValuesReportComponent } from './pages/release-values-report/release-values-report.component';
import { ReleaseValuesComponent } from './pages/release-values/release-values.component';
import { ReleaseValuesDetailsComponent } from './pages/release-values/release-values-details/release-values-details.component';
import { RefundRateComponent } from './pages/contracted-plans/refund-rate/refund-rate.component';
import { CommunicationComponent } from './pages/communication/communication.component';
import { CustomerAreaComponent } from './pages/customer-area/customer-area.component';
import { ClientGuard } from './helpers/guards/unauth.guard';
import { CreateInternalUserComponent } from './pages/user-management/create-internal-user/create-internal-user.component';
import { EditInternalUserComponent } from './pages/user-management/edit-internal-user/edit-internal-user.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { CreateExternalUserComponent } from './pages/user-management/create-external-user/create-external-user.component';
import { EditExternalUserComponent } from './pages/user-management/edit-external-user/edit-external-user.component';
import { ProductsComponent } from './pages/products/products.component';
import { ParameterRegistrationTariffComponent } from './pages/parameter-registration-tariff/parameter-registration-tariff.component';
import { ParameterRegistrationCommissionComponent } from './pages/parameter-registration-commission/parameter-registration-commission.component';
import { OldTablesTariffComponent } from './pages/parameter-registration-tariff/old-tables-tariff/old-tables-tariff.component';
import { ListDraftComponent } from './pages/draft/list-draft/list-draft.component';
import { OldTablesCommissionsComponent } from './pages/parameter-registration-commission/old-tables-commissions/old-tables-commissions.component';
import { IneligibilityReportComponent } from './pages/ineligibility-report/ineligibility-report.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ConcessionairesAndPartnersComponent } from './pages/concessionaires-and-partners/concessionaires-and-partners.component';
import { FinancialMovementComponent } from './pages/financial-movement/financial-movement.component';
import { SegregationReportComponent } from './pages/segregation-report/segregation-report.component';
import { ChangeReportComponent } from './pages/change-report/change-report.component';
import { ParametersComponent } from './pages/parameters/parameters.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { CreateProfileComponent } from './pages/user-profile/create-profile/create-profile.component';
import { ConcessionairesAndPartnersB2b2cComponent } from './pages/concessionaires-and-partners-b2b2c/concessionaires-and-partners-b2b2c.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'area',
    component: AreaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'simulation',
    component: SimulationComponent
  },
  {
    path: 'simulation/:hash',
    component: SimulationComponent
  },
  {
    path: 'cadastro',
    component: RegisterComponent,
  },
  {
    path: 'accounting',
    component: AccountingComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.GERAR_ARQUIVO_CONTABIL] }
  },
  {
    path: 'planos-contratados',
    component: ContractedPlansComponent,
    canActivate: [AuthGuard, AuthorizationGuard, ClientGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CONSULTAR_PLANO] }
  },
  {
    path: 'commissions',
    component: CommissionsComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.EXTRAIR_RELATORIO_COMISSAO] }
  },
  {
    path: 'position-report',
    component: PositionReportComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.EXTRAIR_RELATORIO_POSICAO_CONTABIL] }
  },
  {
    path: 'contract-extract-report',
    component: ContractExtractReportComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.EXTRAIR_RELATORIO_EXTRATO_CONTRATO] }
  },
  {
    path: 'release-values-report',
    component: ReleaseValuesReportComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.EXTRAIR_RELATORIO_LIBERACAO_VALORES] }
  },
  {
    path: 'release-values',
    component: ReleaseValuesComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.DEVOLVER_TARIFAS_VALORES] }
  },
  {
    path: 'release-values-details',
    component: ReleaseValuesDetailsComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.DEVOLVER_TARIFAS_VALORES] }
  },
  {
    path: 'refound-rate',
    component: RefundRateComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.DEVOLVER_TARIFAS_VALORES] }
  },
  {
    path: 'communication',
    component: CommunicationComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CADASTRAR_MENSAGEM] }
  },
  {
    path: 'customer-area',
    component: CustomerAreaComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CONSULTAR_PLANO] }
  },
  {
    path: 'create-internal-user',
    component: CreateInternalUserComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CADASTRAR_NOVO_USUARIO_INTERNO] }
  },
  {
    path: 'edit-internal-user/:login',
    component: EditInternalUserComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.EDITAR_USUARIO_INTERNO] }
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CADASTRAR_NOVO_USUARIO_INTERNO, EPermissaoAcesso.CADASTRAR_NOVO_USUARIO, EPermissaoAcesso.CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA ] }
  },

  {
    path: 'create-external-user',
    component: CreateExternalUserComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CADASTRAR_NOVO_USUARIO, EPermissaoAcesso.CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA] }
  },
  {
    path: 'edit-external-user/:login',
    component: EditExternalUserComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CADASTRAR_NOVO_USUARIO, EPermissaoAcesso.CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA] }
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.LISTAR_PRODUTOS] }
  },
  {
    path: 'parameter-tariff',
    component: ParameterRegistrationTariffComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.LISTAR_PARAMETROS] }
  },
  {
    path: 'parameter-commission',
    component: ParameterRegistrationCommissionComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.LISTAR_PARAMETROS] }
  },
  {
    path: 'old-tables-tariff',
    component: OldTablesTariffComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.HISTORICO_PARAMETROS] }
  },
  {
    path: 'old-tables-commissions',
    component: OldTablesCommissionsComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.HISTORICO_PARAMETROS] }
  },
  {
    path: 'draft-list',
    component: ListDraftComponent,
  },
  {
    path: 'ineligibility-report',
    component: IneligibilityReportComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.EXTRAIR_RELATORIO_INELEGIBILIDADE] }
  },
  {
    path: 'forgot-password/confirm/:hash',
    component: ForgotPasswordComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'concessionaires-and-partners',
    component: ConcessionairesAndPartnersComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CREDENCIAMENTO_CONCESSIONARIA] }
  },
  {
    path: 'concessionaires-and-partners-b2b2c',
    component: ConcessionairesAndPartnersB2b2cComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CONSULTAR_CONCESSIONARIA] }
  },
  {
    path: 'financial-movement',
    component: FinancialMovementComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.EXTRAIR_RELATORIO_CONCILIACAO_MOVIMENTACAO] }
  },
  {
    path: 'segregation-report',
    component: SegregationReportComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.EXTRAIR_RELATORIO_SEGREGACAO] }
  },
  {
    path: 'change-report',
    component: ChangeReportComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.EXTRAIR_RELATORIO_MUDANCA_STATUS] }
  },
  {
    path: 'parameters',
    component: ParametersComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CADASTRAR_MENSAGEM, EPermissaoAcesso.CONSULTAR_CONCESSIONARIA] }
  },
  {
    path: 'user-profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CADASTRAR_NOVO_USUARIO_INTERNO, EPermissaoAcesso.CADASTRAR_NOVO_USUARIO, EPermissaoAcesso.CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA ] }
  },
  {
    path: 'create-user-profile',
    component: CreateProfileComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    data: { allowedPermissions: [EPermissaoAcesso.CADASTRAR_NOVO_USUARIO_INTERNO, EPermissaoAcesso.CADASTRAR_NOVO_USUARIO, EPermissaoAcesso.CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA ] }
  },


];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
