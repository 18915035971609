import { LiveAnnouncer } from '@angular/cdk/a11y';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { TooltipComponent } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { timeout, catchError, TimeoutError, throwError } from 'rxjs';
import { ButtonComponent } from 'src/app/components/buttons/button/button.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { LinkBackComponent } from 'src/app/components/link-back/link-back.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { ModalQrCodeComponent } from 'src/app/components/modal-qr-code/modal-qr-code.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { HasAuthorizationDirective } from 'src/app/shared/directives/has-authorization.directive';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';
import { CnpjPipe } from 'src/app/shared/pipes/cnpj.pipe';

@Component({
  selector: 'yfs-concessionaires-and-partners-b2b2c',
  standalone: true,
  imports: [ HeaderComponent, LinkBackComponent, HasAuthorizationDirective, ButtonComponent, MatCard, MatCardContent, NgIf, MatIcon, MatFormField, MatLabel, MatInput, FormsModule, ReactiveFormsModule, MatSelect, MatCheckbox, NgFor, MatOption, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, TooltipComponent, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, NgClass, MatPaginator, LoadingSpinnerComponent, CnpjPipe],
  templateUrl: './concessionaires-and-partners-b2b2c.component.html',
  styleUrl: './concessionaires-and-partners-b2b2c.component.scss'
})
export class ConcessionairesAndPartnersB2b2cComponent {
  @ViewChild('firstTableSort')
  firstTableSort!: MatSort;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  public previousScreen: string = '';
  public searchString = new FormControl('');
  public tipo = new FormControl();
  public status = new FormControl();
  public grupoFinanceiro = new FormControl();
  public hasValue = true;
  public length = 50;
  public pageSize = 10;
  public pageIndex = 0;
  public pageSizeOptions = [5, 10, 20];
  public isLoading = false;
  public idTipoValue = 0;
  public showAlertBox = false;
  public inative = 0;
  public currentFlow = '';
  public listaTipo = [{
    IdTipo: 1,
    Nome: "Concessionária"
  },
  {
    IdTipo: 2,
    Nome: "Parceiro"
  }
  ];
  listaStatus = [{
    IdStatus: 1,
    Nome: 'Credenciada para a venda'
  },
  {
    IdStatus: 5,
    Nome: 'Inativas'
  },
  {
    IdStatus: 2,
    Nome: 'Não credenciada para a venda'
  },
  {
    IdStatus: 3,
    Nome: 'Não definidas'
  },]
  public listaGrupoFinanceiro: { codigoGrupo: number; nomeGrupo: string }[] = [];
  public selectedTipo: number[] = []
  public selectedGroup: number[] = []
  public idsTipo: number[] = []
  public idsGroup: number[] = []
  public checkedAllTipo = false;
  public checkedAllGroup = false;
  public checkedAllStatus = false;
  public selectedStatus: number[] = [];
  public idsStatus: number[] = [];
  public listRecords: any;
  public idConcessionariaAccredit = 0;
  public idConcessionariaDecertify = 0;
  public idConcessionariaDecertifyDestino = 0;

  displayedColumns: string[] = ['codigo', 'razaoSocial', 'grupoFinanceiroNome', 'link', 'qrcode'];
  dataSource: any

  constructor(
    private router: Router,
    private _liveAnnouncer: LiveAnnouncer,
    private apiService: ApiHttpService,
    private customPaginator: MatPaginatorIntl,
    public dialog: MatDialog,
  ) {
    customPaginator.itemsPerPageLabel = "Itens por página";
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { previousScreen: string };
    this.previousScreen = state?.previousScreen || '';
  }

  ngOnInit() {
    this.search();
  }

  getBack() {
    if (this.previousScreen === 'parameters') {
      this.router.navigate(['parameters']);
    } else {
      this.router.navigate(['area']);
    }
  }

  getRangeLabel(page: number, pageSize: number, length: number) {
    const totalPages = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${totalPages}`;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.search()
  }

  setPageStart() {
    this.pageIndex = 0;
  }

  search() {
    if(this.idsTipo.length > 1){
      this.idsTipo = []
    }
    const searchValue = this.searchString.value ?? ""
    this.isLoading = true;
    this.apiService.getConcessionariasIntegracaoB2b2c(searchValue, this.pageIndex + 1, this.pageSize).pipe(
      timeout(60000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    ).subscribe({
      next: result => {
        this.isLoading = false;
        this.listRecords = result.items;
        this.inative = result.counterContracts;
        if (result?.hasContract) this.showAlertBox = true;
        else this.showAlertBox = false;
        this.dataSource = new MatTableDataSource<any>(this.listRecords);
        if (this.listRecords && this.listRecords.length === 0) this.hasValue = false
        else this.hasValue = true;
        setTimeout(() => {
          this.dataSource.sort = this.firstTableSort;
        }, 100)
        this.length = result.totalCount;
        this.customPaginator.getRangeLabel = this.getRangeLabel;
      },
      error: error => {
        this.isLoading = false;
        console.log(error)
      }
    })
  }

  openQrCodeModal(hashCode: string, razaoSocial: string) {
    const baseUrl = window.location.origin;
    const link = `${baseUrl}/simulation/${hashCode}`;
    this.dialog.open(ModalQrCodeComponent, {
      data: { link, razaoSocial },
      autoFocus: false
    });
  }

  getSimulationLink(hashCode: string): string {
    const baseUrl = window.location.origin;
    return `${baseUrl}/simulation/${hashCode}`;
}

}
