<mat-card>
  <mat-card-content>
    <span class="title-document">
      {{ concessionaria ? 'Concessionária do seu contrato' : 'Selecione a concessionária do seu contrato' }}
    </span>
<div class="container-content">
  <div *ngIf="!concessionaria" class="content">
    <mat-form-field class="inputs" appearance="fill">
      <mat-label>Estado</mat-label>
      <mat-select data-test="select-uf" [formControl]="estado">
        <mat-option *ngFor="let enum of listUfs" [value]="enum.key">{{enum.value}}</mat-option>
      </mat-select>
      <mat-error *ngIf="estado.invalid">
        O campo é obrigatorio
      </mat-error>
    </mat-form-field>

    <mat-form-field class="inputs" appearance="fill">
      <mat-label>Cidade</mat-label>
      <mat-select data-test="select-city" [formControl]="cidade" [disabled]="!estado.value">
        <mat-option *ngFor="let city of listCities" [value]="city.key">{{city.value}}</mat-option>
      </mat-select>
      <mat-error *ngIf="cidade.invalid">
        O campo é obrigatorio
      </mat-error>
    </mat-form-field>

    <mat-form-field class="input" appearance="fill">
      <mat-label>Concessionária</mat-label>
      <input matInput [matAutocomplete]="auto" [formControl]="concessionarias" placeholder="Selecione" [disabled]="!cidade.value">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let concessionaria of listaConcessionarias" [value]="concessionaria">
          {{ concessionaria.razaoSocial }}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="concessionarias.invalid">
        Selecione uma concessionária.
      </mat-error>
    </mat-form-field>
  </div>

    <div *ngIf="concessionaria" class="content">
      <ng-container>
        <mat-form-field class="inputs" appearance="fill">
          <mat-label>Concessionária</mat-label>
          <input matInput [formControl]="concessionarias">
        </mat-form-field>
      </ng-container>
    </div>
    <div class="container-buttons">
      <div class="divisor-content">
          <yfs-button state="enabled" label="CANCELAR" type="tertiary" (click)="openDialog()"></yfs-button>
          <span class="remaining-number">5 de 5</span>
      </div>
      <div class="divisor-content-next">
        <yfs-button state="enabled" label="VOLTAR" type="secondary" (click)="goBack()"></yfs-button>
        <yfs-button [state]="
    concessionaria ? (concessionarias.hasError('required') || !concessionarias.value ? 'disabled' : 'enabled')
    : (concessionarias.hasError('required') || !estado.value || !cidade.value || !concessionarias.value ? 'disabled' : 'enabled')" label="PROSSEGUIR" type="primary" [press]="nextStep"></yfs-button>
      </div>
  </div>
  <div class="mobile-buttons">
    <span class="remaining-number">5 de 5</span>
    <yfs-button [state]="
    concessionaria ? (concessionarias.hasError('required') || !concessionarias.value ? 'disabled' : 'enabled')
    : (concessionarias.hasError('required') || !estado.value || !cidade.value || !concessionarias.value ? 'disabled' : 'enabled')" label="PROSSEGUIR" class="button" type="primary" [fullWidth]="isFullWidth" [press]="nextStep"></yfs-button>
    <yfs-button state="enabled" label="VOLTAR" type="secondary" [fullWidth]="isFullWidth" class="button" (click)="goBack()"></yfs-button>
    <yfs-button state="enabled" label="CANCELAR" type="tertiary" [fullWidth]="isFullWidth" class="button" (click)="openDialog()"></yfs-button>
</div>
</div>
</mat-card-content>
</mat-card>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
