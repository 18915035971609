import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'telefone',
    standalone: true
})
export class TelefonePipe implements PipeTransform {
    transform(value: string | number | null | undefined): string {
        if (!value) return '-'
        const telefone = BigInt(value).toString();
        switch (telefone.length) {
            case 11:
                return this.formatDDDNonoDigito(telefone)
            case 10:
                return this.formatDDDSemNonoDigito(telefone)
            case 9:
                return this.formatSemDDDNonoDigito(telefone)
            case 8:
                return this.formatApenasNumero(telefone)
            default:
                return telefone.length > 11 ? this.preparaNumero(telefone) : telefone;
        }
    }

    private formatDDDNonoDigito(value: string): string {
        const ddd = value.substring(0, 2);
        const nonoDigito = value.substring(3, 2);
        const primeiraParte = value.substring(3, 7);
        const segundaParte = value.substring(7, 11);
        return `(${ddd}) ${nonoDigito} ${primeiraParte}-${segundaParte}`;
    }

    private formatSemDDDNonoDigito(value: string): string {
        const nonoDigito = value.substring(0, 1);
        const primeiraParte = value.substring(1, 5);
        const segundaParte = value.substring(5, 9);
        return `${nonoDigito} ${primeiraParte}-${segundaParte}`;
    }

    private formatDDDSemNonoDigito(value: string): string {
        const ddd = value.substring(0, 2);
        const primeiraParte = value.substring(2, 6);
        const segundaParte = value.substring(6, 10);
        return `(${ddd}) ${primeiraParte}-${segundaParte}`;
    }

    private formatApenasNumero(value: string): string {
        const primeiraParte = value.substring(0, 4);
        const segundaParte = value.substring(4, 8);
        return ` ${primeiraParte}-${segundaParte}`;
    }

    private preparaNumero(value: string): string {
        return this.formatDDDNonoDigito(value.substring(value.length, value.length - 11));
    }

}
