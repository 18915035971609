import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TimeoutError, catchError, tap, throwError, timeout } from 'rxjs';
import { ModalAccountGenerationComponent } from 'src/app/components/modal-account-generation/modal-account-generation.component';
import { ModalGenericComponent } from 'src/app/components/modal-generic/modal-generic.component';
import { IAddress, IConcessionaireRegister, IContact, IDocuments, IPersonalData, IProductRegister, IProposal } from 'src/app/models/register.interface';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { UserService } from 'src/app/services/user.service';
import { NgIf, DatePipe } from '@angular/common';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../../../components/buttons/button/button.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';
import { LinkBackComponent } from 'src/app/components/link-back/link-back.component';

interface Parametro {
  assinaturaEletronicaHabilitada: boolean;
}

@Component({
    selector: 'yfs-complete',
    templateUrl: './complete.component.html',
    styleUrls: ['./complete.component.scss'],
    standalone: true,
    imports: [NgIf, LinkBackComponent , MatCard, MatCardContent, MatRadioGroup, FormsModule, MatRadioButton, MatFormField, MatLabel, MatInput, ReactiveFormsModule, NgxMaskDirective, MatIcon, ButtonComponent, LoadingSpinnerComponent, DatePipe]
})
export class CompleteComponent {
  @Output() currentFlowChanged = new EventEmitter<string>();

  public product: IProductRegister | undefined
  public documents: IDocuments | undefined
  public personalDatas: IPersonalData | undefined
  public address: IAddress | undefined
  public contact: IContact | undefined
  public proposal: IProposal | undefined;
  public concessionaire: IConcessionaireRegister | undefined;
  public srcResult: any;
  public fileName: any;
  public fileSizeError: boolean = false;
  public showSendCard: boolean = true;
  public genericError: boolean = false;
  public timeoutError = false;
  public isLoading = false;
  public parametros = sessionStorage.getItem("parametros");
  public minuta: string | null = localStorage.getItem("minuta");
  public boleto: string | null = localStorage.getItem("boleto");
  public regulamento: string | null = localStorage.getItem("regulamento");
  public result: string | null = localStorage.getItem("result");
  public cpf: string | null = localStorage.getItem("cpf");
  public idContrato: string | null = localStorage.getItem("idContrato");
  public minutaAssinada: string = '';
  public formaEnvio = 'Whatsapp'
  public celular = new FormControl({ value: '', disabled: true }, [Validators.required]);
  public sms = new FormControl({ value: '', disabled: true }, [Validators.required]);
  public showSms = true;
  public formaEnvioDocumento = ''
  public dataExpiracaoTermoAdesao = ''
  public email = new FormControl({ value: '', disabled: true }, [
    Validators.required,
    Validators.email,
    Validators.maxLength(50)
  ]);
  public validityDate = '31/12/2023';
  public assinaturaEletronicaHabilitada: boolean | null = false;
  public celularMask = ['(', /\d/, /\d/, ')', /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public success: boolean = false;
  public isBoletoBeingGenerated = false;
  public tentativas = 0;
  public loggedIn: boolean;
  public isFullWidth = window.innerWidth < 820;

  constructor(private router: Router, private apiService: ApiHttpService, private userService: UserService, public dialog: MatDialog) {
    this.loggedIn = this.isLogged();
  }

  ngOnInit() {
    this.isLogged()
    this.assinaturaEletronicaHabilitada = this.obterAssinaturaEletronicaHabilitada();
    if (localStorage.getItem('idConcessionariaHash')) {
      this.concessionaire = JSON.parse(atob(localStorage.getItem('idConcessionariaHash') || '{}'));
    } else if (localStorage.getItem('concessionaria')) {
      this.concessionaire = JSON.parse(atob(localStorage.getItem('concessionaria') || '{}'));
    }
    if (localStorage.getItem('produto'))
      this.product = JSON.parse(atob(localStorage.getItem('produto') || '{}'))
    if (localStorage.getItem('documentos'))
      this.documents = JSON.parse(atob(localStorage.getItem('documentos') || '{}'))
    if (localStorage.getItem('dadosPessoais'))
      this.personalDatas = JSON.parse(atob(localStorage.getItem('dadosPessoais') || '{}'))
    if (localStorage.getItem('endereco'))
      this.address = JSON.parse(atob(localStorage.getItem('endereco') || '{}'))
    if (localStorage.getItem('contato'))
      this.contact = JSON.parse(atob(localStorage.getItem('contato') || '{}'))

    this.bidingProposalContract()
  }

  obterAssinaturaEletronicaHabilitada(): boolean | null {
      const parametrosJSON = sessionStorage.getItem('parametros');

      if (parametrosJSON) {
          const parametros: Parametro[] = JSON.parse(parametrosJSON);

          if (parametros.length > 0 && typeof parametros[0].assinaturaEletronicaHabilitada === 'boolean') {
              return parametros[0].assinaturaEletronicaHabilitada;
          }
      }

      return null;
  }

  isLogged(): boolean {
    return this.userService.userIsLogged();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isFullWidth = event.target.innerWidth < 820;
  }

  bidingProposalContract() {
    this.verifyContact()
    const isUserLogged = this.isLogged();
    let canalOrigem = isUserLogged ? 1 : 2;
    if (localStorage.getItem('idHashConcessionaria')) {
      canalOrigem = 3;
    }
    let client = {
      documentos: {
        cpf: this.documents?.cpf.toString(),
        rg: {
          numero: this.documents?.rg.numero ? this.documents.rg.numero.replace(/[^a-zA-Z0-9]/g, '') : "",
          uf: this.documents?.rg.uf,
          orgaoExpedidor: this.documents?.rg.orgaoExpedidor,
          dataExpedicao: this.documents?.rg.dataExpedicao
        }
      },
      dadosPessoais: {
        nomeCompleto: this.personalDatas?.nomeCompleto ? this.personalDatas?.nomeCompleto.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : "",
        dataNascimento: this.personalDatas?.dataNascimento,
        estadoCivil: this.personalDatas?.estadoCivil,
        sexo: this.personalDatas?.sexo,
        nomeMae: this.personalDatas?.nomeMae ? this.personalDatas?.nomeMae.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : "",
      },
      endereco: this.address,
      contato: {
        celularComDdd: this.contact?.celularComDdd ? this.contact?.celularComDdd.toString() : undefined,
        telefoneResidencialComDdd: this.contact?.telefoneResidencialComDdd ? this.contact?.telefoneResidencialComDdd.toString() : undefined,
        email: this.contact?.email
      },
    };
    let planoContratado = {
      idProduto: this.product?.idProduto,
      valorReferencia: this.product?.valorReferencia,
      porcentagemPlano: this.product?.porcentagemPlano,
      idFormaPagamento: this.product?.idFormaPagamento,
      valorTotalLiquido: this.product?.valorTotalLiquido,
      numeroParcelas: this.product?.numeroParcelas,
      valorParcelaBruta: this.product?.valorParcelaBruta,
      idConcessionaria: localStorage.getItem('idHashConcessionaria')
      ? localStorage.getItem('idHashConcessionaria')
      : (isUserLogged ? this.concessionaire?.idConcessionaria : null),
      idConcessionariaRelacionamento: localStorage.getItem('idHashRelacionamento')
      ? localStorage.getItem('idHashRelacionamento') : this.concessionaire?.idConcessionaria || null,
      canalOrigem: canalOrigem,
    }
    this.proposal = {
      planoContratado: planoContratado,
      cliente: client,
      formaEnvioDocumento: this.formaEnvio
    }
  }

  verifyContact(){
    if (this.contact) {
      if (this.contact.celularComDdd) {
          this.celular.setValue(this.contact.celularComDdd.toString());
          this.showSms = true;
          this.celularMask = ['(', /\d/, /\d/, ')', /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      } else if (this.contact.telefoneResidencialComDdd) {
          this.celular.setValue(this.contact.telefoneResidencialComDdd.toString());
          this.showSms = false;
          this.celularMask = ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      } else {
          this.celular.setValue(null);
      }
      this.sms.setValue(this.contact.celularComDdd?.toString() ?? null);
      this.email.setValue(this.contact.email ?? null);
  }
  }

  openDialogSucess() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        text: 'Termo de adesão enviado com sucesso.',
        icon: 'success',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      },
    });
  }

  openDialogGenericError() {
    this.dialog.open(ModalGenericComponent, {
      width: '350px',
      data: {
        text: 'Não foi possível enviar o termo de adesão. Tente novamente mais tarde.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

 onFileSelected(event: any) {
    const inputNode = event.target;
    const selectedFile = inputNode.files[0];

    if (!selectedFile) {
      this.fileSizeError = false;
  }

    if (selectedFile.size > 5242880) {
      this.fileSizeError = true;
    } else {
      this.fileSizeError = false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
        this.srcResult = reader.result;
        this.minutaAssinada = this.srcResult.replace("data:application/pdf;base64,", '' );
    };

    this.fileName = selectedFile.name;
}

  onRemoveFile() {
    this.fileName = null;
    const inputNode = document.querySelector('input[type="file"]') as HTMLInputElement;
    inputNode.value = null ?? '';
    this.fileSizeError = false;
  }

  onSend(){
    if(this.fileSizeError){
      this.openDialogGenericErrorContract();
      return;
    }else{
    this.isLoading = true;
    this.apiService.postMinuta(this.cpf, this.minutaAssinada).pipe(
      timeout(30000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          this.timeoutError = true;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
        }
        return throwError(() => error)
      })
    ).subscribe({
      next: result => {
        this.success = true;
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
        if (this.timeoutError === false) this.openDialogGenericErrorContract();
        this.success = false;
        console.log(error)
      }
    })
  }
  }

  openDialogGenericErrorContract() {
    this.dialog.open(ModalGenericComponent, {
      width: '350px',
      data: {
        text: 'Não foi possível concluir a contratação. Tente novamente mais tarde.',
        icon: 'error',
        primaryButtonAction: 'close',
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    })
  }

  gerarMinuta() {
    const byteCharacters = atob(this.minuta || '');
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    setTimeout(() => {
      link.click();
    }, 500);
  }

  gerarBoleto() {
    this.isBoletoBeingGenerated = true;
    this.tryGenerate();
  }

  tryGenerate() {
    const idContrato = Number(this.idContrato)
    if (this.boleto !== 'null' && this.boleto !== null) {
      this.generateBoleto();
  } else if (this.tentativas < 3) {
      this.tentativas++;
      this.apiService.getInstallment(idContrato, 1).subscribe({
        next: (response) => {
          const boleto = response.body?.boleto?.boleto;
          if (boleto) {
            this.boleto = boleto;
            this.generateBoleto();
          } else {
            setTimeout(() => {
              this.tryGenerate();
            }, 2000);
          }
        },
        error: (error) => {
          console.error('Erro ao obter o boleto:', error);

          if (this.tentativas < 3) {
            setTimeout(() => {
              this.tryGenerate();
            }, 2000);
          } else {
            this.openModalModalAccountGeneration();
            this.isBoletoBeingGenerated = false;
            this.tentativas = 0;
          }
        }
      });
    } else {
      this.openModalModalAccountGeneration();
      this.isBoletoBeingGenerated = false;
      this.tentativas = 0;
    }
}

generateBoleto() {
    const byteCharacters = atob(this.boleto as any);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    setTimeout(() => {
        link.click();
        this.isBoletoBeingGenerated = false;
        this.tentativas = 0;
    }, 3000);
}

  openModalModalAccountGeneration() {
    this.dialog.open(ModalAccountGenerationComponent, {
      width: 'auto',
      height: 'auto',
    })
  }

    gerarRegulamento(){
     const byteCharacters = atob(this.regulamento || '');
     const byteNumbers = new Array(byteCharacters.length);
     for (let i = 0; i < byteCharacters.length; i++) {
       byteNumbers[i] = byteCharacters.charCodeAt(i);
     }
     const byteArray = new Uint8Array(byteNumbers);
     const blob = new Blob([byteArray], { type: 'application/pdf' });
     const url = URL.createObjectURL(blob);
     const link = document.createElement("a");
     link.href = url;
     link.target = "_blank";
     link.setAttribute('target', '_blank');
     link.setAttribute('rel', 'noopener noreferrer');
     setTimeout(() => {
       link.click();
     }, 500);
    }

    goHome() {
      localStorage.removeItem("result")
      localStorage.removeItem("regulamento")
      localStorage.removeItem("boleto")
      localStorage.removeItem("minuta")
      localStorage.removeItem("cpf")
      localStorage.removeItem("produto")
      this.router.navigate(["home"])
    }

    verifyShipping(){
      switch (this.formaEnvio) {
        case "Email":
      return this.openDialogEmail();
        case "Whatsapp":
      return this.openDialogWhatsapp();
        case "Sms":
      return this.openDialogSms();
    }
  }

  openDialogEmail() {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'warning',
        text: `Tem certeza que deseja enviar o termo de adesão para o e-mail abaixo?`,
        highlightText: this.email.value,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'SIM, ENVIAR',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => {this.dialog.closeAll(); this.register()}
      },
    });
  }

  openDialogWhatsapp() {
    const numeroFormatado = this.formatarNumeroTelefone(this.celular.value, this.contact);
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'warning',
        text: `Tem certeza que deseja enviar o termo de adesão para o whatsapp abaixo?`,
        highlightText: numeroFormatado,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'SIM, ENVIAR',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => {this.dialog.closeAll(); this.register()}
      },
    });
  }

  openDialogSms() {
    const numeroFormatado = this.formatarNumeroTelefone(this.sms.value, this.contact);
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'warning',
        text: `Tem certeza que deseja enviar o termo de adesão para o SMS abaixo?`,
        highlightText: numeroFormatado,
        primaryButtonAction: 'close',
        primaryButtonText: 'VOLTAR',
        primaryButtonVariant: 'secondary',
        secundaryButtonText: 'SIM, ENVIAR',
        secundaryButtonVariant: 'primary',
        secundaryButtonAction: () => {this.dialog.closeAll(); this.register()}
      },
    });
  }

  formatarNumeroTelefone(numero: any, contact: any): string {
    if (contact) {
      if (contact.celularComDdd) {
        return this.aplicarMascara(contact.celularComDdd.toString(), ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]);
      } else if (contact.telefoneResidencialComDdd) {
        return this.aplicarMascara(contact.telefoneResidencialComDdd.toString(), ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]);
      } else {
        return '';
      }
    }
    return numero;
  }

  aplicarMascara(valor: string, mascara: any[]): string {
    const numeros = valor.replace(/\D/g, '');
    const valorArray = numeros.split('');
    const mascaraArray = mascara;

    let resultado = '';
    let j = 0;

    for (let i = 0; i < mascaraArray.length; i++) {
      if (mascaraArray[i] instanceof RegExp) {
        resultado += mascaraArray[i].test(valorArray[j]) ? valorArray[j] : '';
        j++;
      } else {
        resultado += mascaraArray[i];
      }
    }

    return resultado;
  }

  register() {
    this.bidingProposalContract();
    this.isLoading = true;
    this.timeoutError = false;

    const endpoint = this.isLogged() ? this.apiService.postContratoDigital(this.proposal) : this.apiService.postContratoB2c(this.proposal);

    endpoint.pipe(
      timeout(300000),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.isLoading = false;
          this.timeoutError = true;
          return throwError(() => "A requisição demorou muito tempo e foi cancelada.");
        }
        return throwError(() => error);
      })
    ).subscribe({
      next: result => {
        this.isLoading = false;
        if (result.status === 201) {
          this.openDialogSucess();
          this.dataExpiracaoTermoAdesao = result.body.dataExpiracaoTermoAdesao;
          this.regulamento = result.body.regulamento;
          this.showSendCard = false;
          this.clearStorage();
        } else {
          this.openDialogGenericError();
          this.showSendCard = true;
        }
      },
      error: error => {
        this.isLoading = false;
        if (this.timeoutError === false) this.openDialogGenericError();
        console.log(error);
      }
    });
  }

    completeHandle(result: any) {
      this.clearStorage()
      this.currentFlowChanged.emit('complete')
      localStorage.setItem("result", result.sucesso)
      localStorage.setItem("minuta", result.minuta)
      localStorage.setItem("boleto", result.boleto)
      localStorage.setItem("regulamento", result.regulamento)
      localStorage.setItem("cpf", this.documents?.cpf as string)
    }

    clearStorage() {
      localStorage.removeItem("documentos")
      localStorage.removeItem("dadosPessoais")
      localStorage.removeItem("endereco")
      localStorage.removeItem("contato")
      localStorage.removeItem("concessionaria")
      localStorage.removeItem("idContrato")
      localStorage.removeItem("contatoSimulacao")
      localStorage.removeItem("razaoSocialHashRelacionamento")
      localStorage.removeItem("idHashRelacionamento")
      localStorage.removeItem("idHashConcessionaria")
      localStorage.removeItem("hashCode")
      localStorage.removeItem("local")
    }

    back(){
      this.currentFlowChanged.emit("verifyData")
    }

}
