<yfs-header></yfs-header>
<section class="container-content">
    <div class="button-back">
      <yfs-link-back [text]="previousScreen === 'parameters' ? 'Voltar para parâmetros técnicos' : 'Voltar para minha área'" (click)="getBack()"></yfs-link-back>
    </div>
    <div class="container-title">
      <h1>LISTA DE CONCESSIONÁRIAS E PARCEIROS</h1>
  </div>
    <mat-card>
        <mat-card-content>
          <div class="inputs-content">
            <mat-form-field id="name" style="flex: 2" appearance="fill">
              <mat-label>Busque por nome, E-mail ou CNPJ</mat-label>
              <mat-icon aria-hidden="false" aria-label="Ícone de busca"
                class="search-icon material-symbols-outlined" (click)="search()" fontIcon="search"></mat-icon>
              <input [formControl]="searchString" matInput (keyup.enter)="search()" (keydown.tab)="search()">
            </mat-form-field>
          </div>
              <div>
                <table *ngIf="hasValue" mat-table [dataSource]="dataSource" #firstTable #firstTableSort="matSort"
                    matSort (matSortChange)="announceSortChange($event)">

                    <ng-container matColumnDef="codigo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                            Código
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.codigo}} </td>
                    </ng-container>

                    <ng-container matColumnDef="razaoSocial">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by razaoSocial">
                            Concessionária
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="flex-column">
                                {{element.razaoSocial}}
                                <strong>{{element.cnpj | cnpj}}</strong>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="grupoFinanceiroNome">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by grupoFinanceiroNome">
                            Grupo financeiro
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="!element.grupoFinanceiroNome">-</div>
                            <div *ngIf="element.grupoFinanceiroNome" class="flex-column">
                                {{element.grupoFinanceiroNome}}
                                <strong>{{element.grupoFinanceiroCodigo}}</strong>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="link">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by link">
                          Link
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <a [href]="getSimulationLink(element.hashCode)" target="_blank">
                            {{ getSimulationLink(element.hashCode) }}
                        </a>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="qrcode">
                    <th mat-header-cell *matHeaderCellDef>
                        QR Code
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-icon (click)="openQrCodeModal(element.hashCode, element.razaoSocial)">qr_code</mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'transferir-planos-row': (row.status == 4 || row.status == 5) && row.hasContract === true}"></tr>
                </table>
                <div *ngIf="!hasValue" class="content-empty">
                    <img src="assets/svg/empty-document.svg" alt="documento vazio" height="64" width="64">
                    <div>
                      <span>Nenhum resultado encontrado.</span>
                      <span>Tente novamente com outros filtros.</span>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="paginator-content">
        <mat-paginator [pageIndex]="pageIndex" [length]="length" [pageSize]="pageSize" aria-label="Select page"
            [pageSizeOptions]="pageSizeOptions" (page)="handlePageEvent($event)">
        </mat-paginator>
    </div>
</section>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
