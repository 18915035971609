<h1 mat-dialog-title class="dialog-text-first">{{ razaoSocial }}</h1>

<div class="container">
  <div class="dialog-content">
  <qrcode #qrcode [qrdata]="link" errorCorrectionLevel="M"></qrcode>

  <div *ngIf="copiedMessage" class="copied-message">
    Link copiado com sucesso!
  </div>

  <div class="icon-actions">
      <mat-icon (click)="copyLink()">content_copy</mat-icon>
      <mat-icon (click)="downloadQRCode()">download</mat-icon>
  </div>
</div>

<div class="dialog-buttons">
  <yfs-button state="enabled" label="FECHAR" type="primary" (click)="closeModal()"></yfs-button>
</div>
</div>
