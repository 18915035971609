import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ButtonComponent } from '../buttons/button/button.component';
import { ContentViewer } from './dynamic-content-viewer';
import { MatIcon } from '@angular/material/icon';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { ModalGenericComponent } from '../modal-generic/modal-generic.component';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'yfs-modal-preview-draft',
  templateUrl: './modal-preview-draft.component.html',
  styleUrls: ['./modal-preview-draft.component.scss'],
  standalone: true,
  imports: [ ButtonComponent, NgClass, ContentViewer, MatIcon, MatCard, MatCardContent, MatFormField, MatLabel, FormsModule, MatInputModule, NgIf, NgFor]
})
export class ModalPreviewDraftComponent {
  public safePreviewContent: any;
  public search = "";
  public filtedListWildCards: any;
  public listWildCards: any;
  public previewContent: any;
  public isLoading = false;
  public copiedMessage = false;
  public iframeSrc: SafeResourceUrl | null = null;

  constructor(
    public dialogRef: MatDialogRef<ModalPreviewDraftComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {
    if (this.data && this.data.pdf) {
      this.safePreviewContent = this.data.pdf;
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.pdf);
    }
    if (this.data && this.data.previewContent) {
      this.safePreviewContent = this.data.previewContent;
    }
    if (this.data && this.data.listWildCards) {
      this.listWildCards = this.data.listWildCards;
      this.filtedListWildCards = [...this.listWildCards];
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.scrollToTop();
    }, 150);
  }

  scrollToTop() {
    const element = document.getElementById('top');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  applyFilter() {
    const cleanedInput = this.search.toLowerCase().replace(/[%\[\]]/g, '');
    this.filtedListWildCards = this.listWildCards.filter((tagObj: { tag: string; }) => {
      const cleanedTag = tagObj.tag.toLowerCase().replace(/[%\[\]]/g, '');
      return cleanedTag.includes(cleanedInput);
    });
  }

  copySourceCode(): void {
    if (this.safePreviewContent) {
      navigator.clipboard.writeText(this.safePreviewContent).then(() => {
        this.copiedMessage = true;
        this.cdr.detectChanges();

        this.openCopiedSuccessModal();

        setTimeout(() => {
          this.copiedMessage = false;
          this.cdr.detectChanges();
        }, 8000);
      }).catch(err => {
        console.error('Erro ao copiar o conteúdo', err);
      });
    }
  }

  openCopiedSuccessModal(): void {
    this.dialog.open(ModalGenericComponent, {
      width: '384px',
      data: {
        icon: 'success',
        text: `Source code copiado com sucesso.`,
        primaryButtonAction: () => { this.dialog.closeAll(); },
        primaryButtonText: 'FECHAR',
        primaryButtonVariant: 'primary',
      }
    });
  }
}
