<mat-card>
<mat-card-content>
<div class="container-content">
    <div *ngIf="!timeoutError && !genericError">
        <div class="content">
          <div class="container-title">
              <span class="title-document">Produto</span>
          </div>
          <div class="content-form">
              <div>
                  <span class="bold">Modelo</span>
                  <span data-test="model">{{product?.modelo || '-'}}</span>
              </div>
              <div>
                  <span class="bold">Valor</span>
                  <span data-test="value">{{product?.valorReferencia | currency}}</span>
              </div>
              <div>
                  <span class="bold">Porcentagem do plano</span>
                  <span data-test="percentage">{{product?.porcentagemPlano || '-'}}%</span>
              </div>
              <div>
                  <span class="bold">Forma de pagamento</span>
                  <span>{{product?.formaPagamento || '-'}}</span>
              </div>
              <div>
                  <span class="bold">Valor acumulado Liberacred</span>
                  <span data-test="accumulated-value">{{product?.valorTotalLiquido | currency}}</span>
              </div>
              <div>
                  <span class="bold">Valor mensal das recargas</span>
                  <span data-test="installment-value">{{product?.numeroParcelas}}X {{product?.valorParcelaBruta | currency}}</span>
              </div>
          </div>
      </div>
      <mat-divider class="separator"></mat-divider>
          <div class="content" *ngIf="!razaoSocialHashRelacionamento && !getRemainingNumber()">
            <div class="container-title">
                <span class="title-document">Canal de relacionamento</span>
                <button class="button-icon" (click)="getBackHandle('concessionaire')" mat-mini-fab>
                  <mat-icon style="font-size: 23px;">edit</mat-icon>
              </button>
            </div>
            <div class="content-form-concessionaria">
                <div>
                    <span data-test="model">{{concessionaire?.razaoSocial || '-'}}</span>
                </div>
            </div>
        </div>
        <div class="content" *ngIf="!razaoSocialHashRelacionamento && getRemainingNumber()">
          <div class="container-title">
              <span class="title-document">Canal de relacionamento</span>
              <button class="button-icon" (click)="getBackHandle('delivery')" mat-mini-fab>
                <mat-icon style="font-size: 23px;">edit</mat-icon>
            </button>
          </div>
          <div class="content-form-concessionaria">
              <div>
                  <span data-test="model">{{concessionaire?.razaoSocial || '-'}}</span>
              </div>
          </div>
      </div>
      <div class="content" *ngIf="razaoSocialHashRelacionamento">
        <div class="container-title">
          <span class="title-document">Concessionária de entrega</span>
        </div>
        <div class="content-form-concessionaria">
          <div>
            <span data-test="model">{{ razaoSocialHashRelacionamento || '-' }}</span>
          </div>
        </div>
      </div>
        <mat-divider class="separator"></mat-divider>
        <div class="content">
            <div class="container-title">
                <span class="title-document">Documento de identificação</span>
                <button class="button-icon" (click)="getBackHandle('document')" mat-mini-fab>
                    <mat-icon style="font-size: 23px;">edit</mat-icon>
                </button>
            </div>
            <div class="content-form">
                <div>
                    <span class="bold">CPF</span>
                    <span data-test="cpf">{{documents?.cpf | cpf}}</span>
                </div>
                <div *ngIf="hideRg">
                    <span class="bold">RG</span>
                    <span data-test="rg">{{documents?.rg?.numero | rg }}</span>
                </div>
                <div *ngIf="hideRg">
                    <span class="bold">UF do documento</span>
                    <span data-test="uf-document">{{documents?.rg?.uf || '-' }}</span>
                </div>
                <div *ngIf="hideRg">
                    <span class="bold">Órgão expedidor</span>
                    <span data-test="origin-doc">{{documents?.rg?.orgaoExpedidor || '-' }}</span>
                </div>
                <div *ngIf="hideRg">
                    <span class="bold">Data de expedição</span>
                    <span data-test="date-doc">{{documents?.rg?.dataExpedicao | dateConvert }}</span>
                </div>
            </div>
        </div>
        <mat-divider class="separator"></mat-divider>
        <div class="content">
            <div class="container-title">
                <span class="title-document">Dados pessoais</span>
                <button class="button-icon" (click)="getBackHandle('personalData')" mat-mini-fab>
                    <mat-icon style="font-size: 23px;">edit</mat-icon>
                </button>
            </div>
            <div class="content-form">
                <div>
                    <span class="bold">Nome completo</span>
                    <span data-test="name">{{personalDatas?.nomeCompleto || '-'}}</span>
                </div>
                <div>
                    <span class="bold">Data de nascimento</span>
                    <span data-test="birth-date">{{personalDatas?.dataNascimento | dateConvert }}</span>
                </div>
                <div>
                    <span class="bold">Estado civil</span>
                    <span data-test="civil">{{personalDatas?.estadoCivil || '-'}}</span>
                </div>
                <div>
                    <span class="bold">Sexo</span>
                    <span  data-test="bio-sex">{{getSexoDescription(personalDatas?.sexo)}}</span>
                </div>
                <div>
                    <span class="bold">Nome da mãe</span>
                    <span data-test="mothers-name">{{personalDatas?.nomeMae || '-'}}</span>
                </div>
            </div>
        </div>
        <mat-divider class="separator"></mat-divider>
        <div class="content">
            <div class="container-title">
                <span class="title-document">Endereço</span>
                <button class="button-icon" (click)="getBackHandle('address')" mat-mini-fab>
                    <mat-icon style="font-size: 23px;">edit</mat-icon>
                </button>
            </div>
            <div class="content-form">
              <div>
                <span class="bold">Rua/Avendida/Praça</span>
                <span data-test="street">{{address?.logradouro || '-'}}</span>
              </div>
              <div>
                <span class="bold">Número</span>
                <span data-test="number">{{address?.numero || '-'}}</span>
              </div>
              <div>
                <span class="bold">Complemento</span>
                <span data-test="complement">{{address?.complemento || '-'}}</span>
              </div>
              <div>
                <span class="bold">Bairro</span>
                <span data-test="neighborhood">{{address?.bairro || '-'}}</span>
              </div>
              <div>
                <span class="bold">Cidade</span>
                <span data-test="city">{{address?.cidade || '-'}}</span>
              </div>
              <div>
                <span class="bold">Estado</span>
                <span data-test="uf">{{address?.estado || '-'}}</span>
              </div>
              <div>
                <span class="bold">CEP</span>
                <span data-test="cep">{{address?.cep | cep}}</span>
              </div>
            </div>
        </div>
        <mat-divider class="separator"></mat-divider>
        <div class="content">
            <div class="container-title">
                <span class="title-document">Dados de contato</span>
                <button class="button-icon" (click)="getBackHandle('contact')" mat-mini-fab>
                    <mat-icon style="font-size: 23px;">edit</mat-icon>
                </button>
            </div>
            <div class="content-form">
                <div style="width: 260px;">
                    <span class="bold">Celular com DDD</span>
                    <span data-test="phone-cell">{{contact?.celularComDdd | celular}}</span>
                </div>
                <div style="width: 260px;">
                  <span class="bold">Telefone residencial com DDD</span>
                  <span data-test="phone">{{contact?.telefoneResidencialComDdd | telefone}}</span>
                </div>
                <div style="width: 550px;">
                    <span class="bold">E-mail</span>
                    <span data-test="email">{{contact?.email || '-'}}</span>
                </div>
            </div>
        </div>
        <div class="container-buttons">
          <yfs-button state="enabled" label="CANCELAR" type="secondary" class="button" [fullWidth]="isFullWidth" (click)="openDialog()"></yfs-button>
          <yfs-button *ngIf="!loggedIn" state="enabled" label="PROSSEGUIR" type="primary" class="button" [fullWidth]="isFullWidth" (click)="nextStep()"></yfs-button>
          <yfs-button *ngIf="loggedIn && assinaturaEletronicaHabilitada" state="enabled" label="PROSSEGUIR" class="button" type="primary" [fullWidth]="isFullWidth" (click)="nextStep()"></yfs-button>
          <yfs-button *ngIf="loggedIn && !assinaturaEletronicaHabilitada" state="enabled" label="CONCLUIR" class="button" type="primary" [fullWidth]="isFullWidth" (click)="register()"></yfs-button>
        </div>
    </div>
</div>
</mat-card-content>
</mat-card>
<yfs-alert-box *ngIf="timeoutError || genericError"></yfs-alert-box>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
