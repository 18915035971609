import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { catchError, throwError, timeout, TimeoutError } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { EStatus } from 'src/app/shared/enums/status.enum';
import { IContractedItem, IContractedPlans } from 'src/app/models/contracted-plans.interface';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { UserService } from 'src/app/services/user.service';
import { HeaderComponent } from '../../components/header/header.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { LinkBackComponent } from '../../components/link-back/link-back.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaskDirective } from 'ngx-mask';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption } from '@angular/material/core';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { TooltipComponent } from '../../components/tooltip/tooltip.component';
import { MatTooltip } from '@angular/material/tooltip';
import { ClientPlanComponent } from './client-plan/client-plan.component';
import { ClientDataComponent } from './client-data/client-data.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';
import { CpfPipe } from '../../shared/pipes/cpf.pipe';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from 'src/app/shared/format-datepicker/format-datepicker';
import { dateFormat } from 'src/app/shared/date-format';

export interface StatusOption {
  label: string;
  value: number;
}

export interface CanalOrigemOption {
  label: string;
  value: number;
}

interface Documento {
  nome: string;
  assinado: boolean;
  dataExpiracao: string | null;
  tipoAssinatura: string;
}

@Component({
  selector: 'yfs-contracted-plans',
  templateUrl: './contracted-plans.component.html',
  styleUrls: ['./contracted-plans.component.scss'],
  standalone: true,
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  imports: [HeaderComponent, NgIf, MatAutocompleteModule, MatDatepickerModule, LinkBackComponent, MatCard, MatCardContent, MatFormField, MatLabel, MatIcon, MatInput, FormsModule, ReactiveFormsModule, MatDatepickerInput, NgxMaskDirective, MatDatepickerToggle, MatSuffix, MatDatepicker, MatError, MatSelect, NgFor, MatOption, MatIconButton, MatCheckbox, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, TooltipComponent, MatTooltip, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatPaginator, ClientPlanComponent, ClientDataComponent, LoadingSpinnerComponent, DatePipe, CpfPipe]
})
export class ContractedPlansComponent implements OnInit, AfterViewInit {

  @ViewChild('mySel') select: MatSelect | undefined;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;
  @ViewChild('picker') picker: any;

  public searchString = new FormControl('');
  public status = new FormControl([]);
  public origem = new FormControl('');
  public concessionaria = new FormControl();
  public statusFilter = new FormControl('');
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public statusOptions: StatusOption[] = [];
  public canalOrigemOptions: CanalOrigemOption[] | undefined;
  public length = 50;
  public pageSize = 10;
  public pageIndex = 0;
  public pageSizeOptions = [5, 10, 20];
  public isLoading = false;
  public listContractedPlans: IContractedPlans[] | undefined
  public contratosIniciados = undefined;
  public contratosFinalizados = undefined;
  public currentFlow = "contractedPlans"
  public contractedPlanSelected: IContractedItem | undefined
  public hasValue = true;
  public statusControl: string[] = [];
  public origemControl = "";
  public cpfSelected: string | undefined
  public interteminate = false;
  public concessionarias: any[] = [];
  public listaConcessionarias: any[] = []
  public termoAdesaoAssinado = true;
  public checked = false;
  public isTooltipDisabled = false;
  public filteredStatusOptions: StatusOption[] = [];
  public dataInicioContratacao: string | null = null;
  public dataFimContratacao: string | null = null;
  public selectedStatusIds: string[] = [];
  public dateForm: FormGroup;
  public dataInicialLabel: Date | undefined;
  public dataInicial = new FormControl('', [dateFormat.dateValidator]);
  public dataFinalLabel: Date | undefined;
  public dataFinal = new FormControl('', [dateFormat.dateValidator]);

  displayedColumns: string[] = ['numeroContrato', 'nomeCliente', 'cpf', 'dataContratacao', 'canalOrigem', 'statusContrato'];
  dataSource: any

  constructor(
    private cd : ChangeDetectorRef,
    private _liveAnnouncer: LiveAnnouncer,
    private apiService: ApiHttpService,
    private customPaginator: MatPaginatorIntl,
    public dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder
  ) {

    this.dateForm = this.fb.group(
      {
        dataInicioContratacao: [''],
        dataFimContratacao: ['']
      }
    );

    customPaginator.itemsPerPageLabel = "Itens por página";
    this.statusOptions = [
      {
        label: 'Em Aberto',
        value: EStatus.EM_ABERTO
      },
      {
        label: 'Estorno finalizado',
        value: EStatus.ESTORNO_FINALIZADO
      },
      {
        label: 'Estorno em processo',
        value: EStatus.ESTORNO_EM_PROCESSO
      },
      {
        label: 'Elegível',
        value: EStatus.ELEGIVEL
      },
      {
        label: 'Ativo',
        value: EStatus.ATIVO
      },
      {
        label: 'Ativo - Prorrogação',
        value: EStatus.ATIVO_PRORROGADO
      },
      {
        label: 'Finalizado',
        value: EStatus.FINALIZADO_CDC
      },
      {
        label: 'Inelegível',
        value: EStatus.INELEGIVEL
      },
      {
        label: 'Cancelado',
        value: EStatus.CANCELADO
      },
      {
        label: 'Inativo',
        value: EStatus.INATIVO
      },
      {
        label: 'Inatividade finalizada',
        value: EStatus.FINALIZADO_INATIVO
      },
      {
        label: 'Desistência em andamento',
        value: EStatus.DESISTENCIA_EM_PROCESSO
      },
      {
        label: 'Desistência finalizado',
        value: EStatus.DESISTECIA_FINALIZADO
      },
      {
        label: 'Aguardando assinatura do termo de adesão',
        value: EStatus.ASSINATURA_PENDENTE_TERMO_ADESAO
      },
      {
        label: 'Aguardando assinatura do termo de cancelamento',
        value: EStatus.ASSINATURA_PENDENTE_TERMO_CANCELAMENTO
      },
      {
        label: 'Aguardando assinatura do termo de prorrogação',
        value: EStatus.ASSINATURA_PENDENTE_TERMO_PRORROGACAO
      },
      {
        label: 'Liberação de valores CDC',
        value: EStatus.LIBERACAO_VALORES_CDC_EM_PROCESSO
      },
      {
        label: 'Liberação de valores QUITADO ELEGÍVEL',
        value: EStatus.LIBERACAO_VALORES_QUITADO_ELEGIVEL_EM_PROCESSO
      },
      {
        label: 'Liberação de valores QUITADO INELEGÍVEL',
        value: EStatus.LIBERACAO_VALORES_QUITADO_INELEGIVEL_EM_PROCESSO
      }
    ]

    this.canalOrigemOptions = [
      {
        label: 'B2B',
        value: 1
      },
      {
        label: 'B2C',
        value: 2
      },
      {
        label: 'B2B2C',
        value: 3
      },
  ]
  }

  ngOnInit() {
    this.statusFilter.valueChanges.subscribe((value) => {
      if (value) {
        this.filteredStatusOptions = this.statusOptions.filter(option =>
          option.label.toLowerCase().includes(value?.toLowerCase() || '')
        );
      } else {
        this.filteredStatusOptions = this.statusOptions;
      }
    });
    this.concessionariaData();
    this.search();

    this.dateForm.patchValue({
      dataInicioContratacao: this.dataInicioContratacao ? this.dataInicioContratacao : '',
      dataFimContratacao: this.dataFimContratacao ? this.dataFimContratacao : '',
    });
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  filterStatusOptions(searchValue: string) {
    const filterValue = searchValue.toLowerCase();
    this.filteredStatusOptions = (this.statusOptions ?? []).filter(option =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  onFocus() {
    this.filteredStatusOptions = this.statusOptions;
    this.autocompleteTrigger.openPanel();
  }

  clearStatusSelection() {
    this.status.setValue([]);
    this.selectedStatusIds = [];
    this.filteredStatusOptions = [...(this.statusOptions ?? [])];
  }

  onKeyUp(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const value = input?.value || '';
    this.filterStatusOptions(value);
  }

  parseJsonString(jsonString: any) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Erro ao fazer parsing do JSON:", error);
      return null;
    }
  }

  getDateByString(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  protected mySelOnchange(event: any): void {
    if (event.value.length > 10) {
      this.concessionaria.setErrors({'limit': true});
      this.select?.close();
      return;
    }

    this.search()
  }

  @ViewChild('firstTableSort')
  firstTableSort!: MatSort;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  selectClientPlan(row: any) {
    this.contractedPlanSelected = row;
    this.currentFlow = "clientPlan"
  }

  setPageStart() {
    this.pageIndex = 0;
    this.search();
  }

  onBlurEndDate() {
    const dataInicio = this.dateForm.get('dataInicioContratacao')?.value;
    const dataFim = this.dateForm.get('dataFimContratacao')?.value;

    if (!dataInicio || !dataFim) {
      console.error('Datas incompletas ou inválidas. A busca não será realizada.');
      return;
    }

    setTimeout(() => {
      this.setPageStart();
    });
  }

  parseDate(date: any): Date | null {
    if (!date) {
      return null;
    }
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
  }

  onDateChange(event: any, controlName: string): void {
    const value = event.value ? this.formatDate(event.value) : null;
    this.dateForm.get(controlName)?.setValue(value);
    this.dateForm.updateValueAndValidity();
  }
  clearDateInputs() {
    this.dateForm.patchValue({
      dataInicioContratacao: '',
      dataFimContratacao: '',
    });
    this.setPageStart();
  }

  concessionariaData() {
    const user = this.userService.getCurrentUser();
    if (user) {
      this.concessionarias = user.Concessionarias;
    }

    if (!Array.isArray(this.concessionarias)) {
      this.concessionarias = [this.concessionarias];
    }

    const concessionariasFormatadas = this.concessionarias.map(this.parseJsonString);

    this.listaConcessionarias = concessionariasFormatadas.sort((a, b) => {
      if (a.Codigo < b.Codigo) {
        return -1;
      }
      if (a.Codigo > b.Codigo) {
        return 1;
      }
      return 0;
    });

    this.concessionaria.setValue([]);
  }

  formatDateToIso(date: Date | null | undefined): string {
    if (!date || isNaN(date.getTime())) {
      console.error('Data inválida:', date);
      return '';
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  search() {
    const searchValue = this.searchString.value ?? "";
    const statusValue = (this.status.value ?? []).map(id => Number(id));
    const canalOrigem = this.origem?.value ?? "";
    let searchIsValid = true;
    let assinaturaPendente = this.checked;

    const dataInicioControl = this.dateForm.get('dataInicioContratacao')?.value;
    const dataFimControl = this.dateForm.get('dataFimContratacao')?.value;

    const dataInicio = dataInicioControl ? this.formatDateToIso(this.parseDate(dataInicioControl)!) : null;
    const dataFim = dataFimControl ? this.formatDateToIso(this.parseDate(dataFimControl)!) : null;


    if (searchIsValid) {
      this.isLoading = true
      this.apiService.getContractedPlans(
        searchValue,
        dataInicio ? dataInicio : undefined,
        dataFim ? dataFim : undefined,
        statusValue,
        assinaturaPendente,
        this.concessionaria.value,
        canalOrigem,
        this.pageIndex + 1,
        this.pageSize).pipe(
        timeout(30000),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            this.isLoading = false;
            return throwError(() => "A requisição demorou muito tempo e foi cancelada.")
          }
          return throwError(() => error)
        })
      )
        .subscribe({
          next: result => {
            this.isLoading = false;
            this.listContractedPlans = result.planosContratados.items;
            this.contratosIniciados = result.contratosIniciadosMesCorrente;
            this.contratosFinalizados = result.contratosFinalizadosMesCorrente;
            this.dataSource = new MatTableDataSource<IContractedPlans>(this.listContractedPlans);
            if (this.listContractedPlans && this.listContractedPlans.length === 0) this.hasValue = false
            else this.hasValue = true;
            this.dataSource.sort = this.firstTableSort;
            this.length = result.planosContratados.totalCount
            this.customPaginator.getRangeLabel = this.getRangeLabel
            this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: any) => {
              if (sortHeaderId === 'statusContrato') {
                return data.statusContratoDescricao;
              }
              return data[sortHeaderId];
            };
            this.isLoading = false;
          },
          error: error => {
            this.isLoading = false;
            console.log(error)
          }
        })
    }
  }

  getCanalOrigem(canalOrigem: number) {
    switch (canalOrigem) {
      case 1:
        return "B2B";
      case 2:
        return "B2C";
      case 3:
        return "B2B2C";
      default:
        return ""
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.search()
  }

  getIcon(status: number) {
    switch (status) {
      case 0:
      case 10:
        return { icon: "nest_clock_farsight_analog_outline", style: "outlined" };
      case 1:
      case 19:
        return { icon: "check_circle_outline", style: "outlined" };
      case 2:
        return { icon: "check_circle", style: "filled" };
      case 3:
        return { icon: "warning", style: "outlined" };
      case 4:
        return { icon: "cancel", style: "outlined" };
      case 7:
        return { icon: "check_circle", style: "outlined" };
      case 5:
        return { icon: "check_circle", style: "filled" };
      case 6:
      case 9:
      case 11:
      case 12:
      case 16:
      case 17:
        return { icon: "attach_money", style: "outlined" };
      case 8:
      case 13:
        return { icon: "block", style: "outlined" };
      case 14:
      case 15:
      case 18:
        return { icon: "signature", style: "outlined" };
      default:
        return { icon: "", style: "" };
    }
  }

  getIconColor(status: number) {
    switch (status) {
      case 0:
        return "var(--color-functional-positive-1)";
      case 10:
        return "var(--color-complementary-yellow-1)";
      case 1:
      case 18:
      case 19:
        return "var(--color-primary-2)";
      case 2:
        return "var(--color-primary-3)";
      case 3:
      case 11:
      case 12:
      case 14:
      case 15:
      case 16:
      case 17:
        return "var(--color-functional-warning-1)";
      case 7:
        return "var(--color-neutral-gray-4)";
      default:
        return "var(--color-neutral-gray-4)";
    }
  }

  getStatusDescription(status: number) {
    switch (status) {
      case 0:
        return "Em Aberto";
      case 1:
        return "Ativo";
      case 2:
        return "Elegível";
      case 3:
        return "Inelegível";
      case 4:
        return "Cancelado"
      case 5:
        return "Desistência finalizado"
      case 6:
        return "Liberação de valores finalizado"
      case 7:
        return "Finalizado"
      case 8:
        return "Inativo"
      case 9:
        return "Estorno finalizado"
      case 10:
        return "Desistência em andamento"
      case 11:
        return "Liberação de valores CDC"
      case 12:
        return "Estorno em processo"
      case 13:
        return "Inatividade finalizada"
      case 14:
        return 'Aguardando assinatura do termo de adesão';
      case 15:
        return 'Aguardando assinatura do termo de cancelamento';
      case 16:
        return 'Liberação de valores QUITADO ELEGÍVEL';
      case 17:
        return 'Liberação de valores QUITADO INELEGÍVEL';
      case 18:
        return 'Aguardando assinatura do termo de prorrogação';
      case 19:
        return 'Ativo - Prorrogação';
      default:
        return ""
    }
  }

  getTooltipText(element: any): string {
    let documentosPendentes = 'Documentos pendentes de assinatura:';

    if (element.documentos && element.documentos.length > 0) {
      const termoAdesao = element.documentos.find((doc: Documento) => doc.nome === 'TermoAdesao');
      if (termoAdesao && !termoAdesao.assinado) {
        if (termoAdesao.tipoAssinatura === 'Manual') {
          documentosPendentes += ` • Termo de adesão ao Liberacred`;
        } else if (termoAdesao.tipoAssinatura === 'Eletrônica') {
          documentosPendentes += ` • Termo de adesão ao Liberacred <br> Assinar até: ${this.formatDate(termoAdesao.dataExpiracao)}`;
        }
      }

      const termoCancelamento = element.documentos.find((doc: Documento) => doc.nome === 'TermoCancelamento');
      if (termoCancelamento && !termoCancelamento.assinado) {
        documentosPendentes += ` <br> • Termo de cancelamento <br> Assinar até: ${this.formatDate(termoCancelamento.dataExpiracao)}`;
      }

      const termoProrrogacao = element.documentos.find((doc: Documento) => doc.nome === 'TermoProrrogacao');
      if (termoProrrogacao && !termoProrrogacao.assinado && termoProrrogacao.dataExpiracao) {
        const dataExpiracao = new Date(termoProrrogacao.dataExpiracao);
        const dataAtual = new Date();
        if (dataExpiracao.getTime() > dataAtual.getTime()) {
          documentosPendentes += ` <br> • Termo de prorrogação <br> Assinar até: ${this.formatDate(termoProrrogacao.dataExpiracao)}`;
        }
      }
    }

    return documentosPendentes !== 'Documentos pendentes de assinatura:' ? documentosPendentes : 'Nenhum documento pendente.';
  }

  getTooltipTextMobile(element: any): string {
    let documentosPendentes = 'Documentos pendentes de assinatura:';
    const itensPendentes: string[] = [];

    if (element.documentos && element.documentos.length > 0) {
      const termoAdesao = element.documentos.find((doc: Documento) => doc.nome === 'TermoAdesao');
      if (termoAdesao && !termoAdesao.assinado) {
        if (termoAdesao.tipoAssinatura === 'Manual') {
          itensPendentes.push(`Termo de adesão ao Liberacred`);
        } else if (termoAdesao.tipoAssinatura === 'Eletrônica' && termoAdesao.dataExpiracao) {
          const dataExpiracao = new Date(termoAdesao.dataExpiracao);
          const dataAtual = new Date();
          if (dataExpiracao.getTime() > dataAtual.getTime()) {
            itensPendentes.push(`Termo de adesão ao Liberacred - Assinar até: ${this.formatDate(termoAdesao.dataExpiracao)}`);
          }
        }
      }

      const termoCancelamento = element.documentos.find((doc: Documento) => doc.nome === 'TermoCancelamento');
      if (termoCancelamento && !termoCancelamento.assinado && termoCancelamento.dataExpiracao) {
        const dataExpiracao = new Date(termoCancelamento.dataExpiracao);
        const dataAtual = new Date();
        if (dataExpiracao.getTime() > dataAtual.getTime()) {
          itensPendentes.push(`Termo de cancelamento - Assinar até: ${this.formatDate(termoCancelamento.dataExpiracao)}`);
        }
      }

      const termoProrrogacao = element.documentos.find((doc: Documento) => doc.nome === 'TermoProrrogacao');
      if (termoProrrogacao && !termoProrrogacao.assinado && termoProrrogacao.dataExpiracao) {
        const dataExpiracao = new Date(termoProrrogacao.dataExpiracao);
        const dataAtual = new Date();
        if (dataExpiracao.getTime() > dataAtual.getTime()) {
          itensPendentes.push(`Termo de prorrogação - Assinar até: ${this.formatDate(termoProrrogacao.dataExpiracao)}`);
        }
      }
    }

    if (itensPendentes.length > 0) {
      documentosPendentes += ' ' + itensPendentes.join(', ');
    }

    return itensPendentes.length > 0 ? documentosPendentes : 'Nenhum documento pendente.';
}

  formatDate(dateStr: string | null): string {
    if (!dateStr) {
      return 'Data não informada';
    }

    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  shouldShowIcon(element: any): boolean {
    if (element.documentos && element.documentos.length > 0) {
      return element.documentos.some((doc: any) => !doc.assinado);
    }
    return false;
  }

  isTermoAdesaoAssinado(element: any): boolean {
    return element && element.termoAdesaoAssinado === false;
  }

  isTermoCancelamentoAssinado(element: any): boolean {
    return element && element.dataExpiracaoTermoCancelamento === null;
  }

  getRangeLabel(page: number, pageSize: number, length: number) {
    const totalPages = Math.ceil(length / pageSize);
    return `Página ${page + 1} de ${totalPages}`;
  }

  changeCurrentFlow(flow: string) {
    this.search()
    this.currentFlow = flow;
    window.scrollTo(0, 0);
  }

  convertStringToDate(dateInput: Date | string): string {

    if (dateInput instanceof Date) {
      return dateInput.toISOString().split('T')[0];
    }
    if (typeof dateInput === 'string') {
      const [day, month, year] = dateInput.split('/');
      return `${year}-${month}-${day}`;
    }

    throw new TypeError('Invalid date input');
  }

  getBack() {
    this.router.navigate(['area']);
  }

  openEditScreen(row: any) {
    this.cpfSelected = row.cpf;
    this.currentFlow = 'clientData'
  }

  toggleTooltip(event: MouseEvent) {
    this.isTooltipDisabled = !this.isTooltipDisabled;
    event.stopPropagation();
  }

}
