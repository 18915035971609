import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { EPermissaoAcesso } from 'src/app/shared/enums/permissao-acesso.enum';
import { NgIf } from '@angular/common';
import { HasAuthorizationDirective } from '../../shared/directives/has-authorization.directive';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'yfs-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: true,
    imports: [NgIf, HasAuthorizationDirective, MatIcon]
})
export class MenuComponent implements OnInit {
  public isMenuVisible: boolean = false;
  public showPlans: boolean = false;
  public showReport: boolean = false;
  public showAccounting: boolean = false;
  public showUser: boolean = false;
  public showParameters: boolean = false;
  public heightMenu: string | undefined;
  public showOverlay = false
  public userAllowedPermissions = [EPermissaoAcesso.CADASTRAR_NOVO_USUARIO_INTERNO, EPermissaoAcesso.EDITAR_USUARIO_INTERNO, EPermissaoAcesso.CADASTRAR_NOVO_USUARIO, EPermissaoAcesso.CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA]
  public addInternalUserPermission = [EPermissaoAcesso.CADASTRAR_NOVO_USUARIO_INTERNO];
  public addExternalUserPermission = [EPermissaoAcesso.CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA, EPermissaoAcesso.CADASTRAR_NOVO_USUARIO];
  public consultAllowedPermissions = [EPermissaoAcesso.CONSULTAR_PLANO]
  public registerAllowedPermissions = [EPermissaoAcesso.CONTRATAR_PLANO]
  public releaseValuesReportAlowedPermissions = [EPermissaoAcesso.DEVOLVER_TARIFAS_VALORES]
  public plansAlowedPermissions = [EPermissaoAcesso.CONSULTAR_PLANO, EPermissaoAcesso.CONTRATAR_PLANO, EPermissaoAcesso.DEVOLVER_TARIFAS_VALORES]
  public generateReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_POSICAO_CONTABIL, EPermissaoAcesso.EXTRAIR_RELATORIO_COMISSAO, EPermissaoAcesso.EXTRAIR_RELATORIO_LIBERACAO_VALORES, EPermissaoAcesso.EXTRAIR_RELATORIO_EXTRATO_CONTRATO, EPermissaoAcesso.EXTRAIR_RELATORIO_INELEGIBILIDADE]
  public generatePositionReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_POSICAO_CONTABIL]
  public generateComissionsReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_COMISSAO]
  public generateReleaseValuesReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_LIBERACAO_VALORES]
  public generateContractExtractReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_EXTRATO_CONTRATO]
  public accountingAlowedPermissions = [EPermissaoAcesso.GERAR_ARQUIVO_CONTABIL]
  public releaseValuesAlowedPermissions = [EPermissaoAcesso.DEVOLVER_TARIFAS_VALORES]
  public technicalParametersAllowedPermissions = [EPermissaoAcesso.CADASTRAR_MENSAGEM, EPermissaoAcesso.LISTAR_PRODUTOS, EPermissaoAcesso.LISTAR_PARAMETROS, EPermissaoAcesso.HISTORICO_PARAMETROS, EPermissaoAcesso.CREDENCIAMENTO_CONCESSIONARIA, EPermissaoAcesso.CONSULTAR_CONCESSIONARIA, EPermissaoAcesso.GERENCIAR_MINUTA]
  public comunicationAllowedPermissions = [EPermissaoAcesso.CADASTRAR_MENSAGEM]
  public productsAllowedPermissions = [EPermissaoAcesso.LISTAR_PRODUTOS]
  public parameterTariffAllowedPermissions = [EPermissaoAcesso.LISTAR_PARAMETROS]
  public tariffHistoricAllowedPermissions = [EPermissaoAcesso.HISTORICO_PARAMETROS]
  public ineligibilityReportAllowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_INELEGIBILIDADE]
  public concessionairesAndPartnersAllowedPermissions = [EPermissaoAcesso.CREDENCIAMENTO_CONCESSIONARIA]
  public generateFinancialMovementAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_CONCILIACAO_MOVIMENTACAO]
  public generateSegregationReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_SEGREGACAO]
  public generateChangeReportAlowedPermissions = [EPermissaoAcesso.EXTRAIR_RELATORIO_MUDANCA_STATUS]
  public manageMinutaAllowedPermissions = [EPermissaoAcesso.GERENCIAR_MINUTA]
  public consultDealershipAlowedPermissions = [EPermissaoAcesso.CONSULTAR_CONCESSIONARIA]
  public userName: string | null = ''
  public createProfilePermission = [EPermissaoAcesso.CADASTRAR_PERFIL_USUARIO];

  constructor(private cdr: ChangeDetectorRef, private router: Router, private userService: UserService) { }

  ngOnInit() {
    const user = this.userService.getCurrentUser()
    if (user && user.Nome) {
      this.userName = user.Nome;
    }
    const observer = new MutationObserver(() => {
      this.setHeight();
      this.cdr.detectChanges();
    });

    observer.observe(document.body, { attributes: true, childList: true, subtree: true });
  }

  verifyClienteUser(): boolean {
    const user = this.userService.getCurrentUser();
    return user.PerfilAcesso === 'Cliente';
  }

  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
    this.showOverlay = !this.showOverlay
  }

  togglePlans(show: boolean) {
    this.showPlans = show;
  }

  toggleReports(show: boolean) {
    this.showReport = show;
  }

  toggleAccounting(show: boolean) {
    this.showAccounting = show;
  }

  toggleParameters(show: boolean) {
    this.showParameters = show;
  }

  toggleUser(show: boolean) {
    this.showUser = show;
  }

  toggleMenuPlans(show: boolean) {
    this.isMenuVisible = !this.isMenuVisible;
    this.showOverlay = !this.showOverlay
    this.showPlans = show;
  }

  toggleMenuReports(show: boolean) {
    this.isMenuVisible = !this.isMenuVisible;
    this.showOverlay = !this.showOverlay
    this.showReport = show;
  }

  toggleMenuAccounting(show: boolean) {
    this.isMenuVisible = !this.isMenuVisible;
    this.showOverlay = !this.showOverlay
    this.showAccounting = show;
  }

  toggleMenuUser(show: boolean) {
    this.isMenuVisible = !this.isMenuVisible;
    this.showOverlay = !this.showOverlay
    this.showUser = show;
  }

  toggleMenuParameters(show: boolean) {
    this.isMenuVisible = !this.isMenuVisible;
    this.showOverlay = !this.showOverlay
    this.showParameters = show;
  }


  setHeight() {
    const bodyHeight = document.body.scrollHeight;
    const height = bodyHeight - 117;
    this.heightMenu = `${height}px`;
  }

  goToAccounting() {
    this.router.navigate(["accounting"]);
  }

  goToPositionReport(){
     this.router.navigate(["position-report"]);
  }

  goToContractsPlans() {
    this.router.navigate(["planos-contratados"])
  }

  goToSimulation() {
    this.router.navigate(["simulation"])
  }

  goToCommissions() {
    this.router.navigate(["commissions"]);
  }

  goTofinancialMovement() {
    this.router.navigate(["financial-movement"]);
  }

  goToSegregationReport() {
    this.router.navigate(["segregation-report"]);
  }

  goToReleaseValuesReport() {
    this.router.navigate(["release-values-report"])
  }

  goToReleaseValues() {
    this.router.navigate(["release-values"])
  }
  goToContractExtractReport() {
    this.router.navigate(["contract-extract-report"])
  }

  goToComunication() {
    this.router.navigate(["communication"])
  }

  goToMyPlan() {
    this.router.navigate(["customer-area"])
  }

  goToUserList() {
    this.router.navigate(["user-management"])
  }

  goToUserProfile() {
    this.router.navigate(["user-profile"])
  }

  goToRegisterInternalUser() {
    this.router.navigate(["create-internal-user"])
  }

  goToRegisterExternalUser() {
    this.router.navigate(["create-external-user"])
  }

  goToProducts() {
    this.router.navigate(["products"])
  }

  goToParameterTariff() {
    this.router.navigate(["parameter-tariff"])
  }

  goToParameterCommission() {
    this.router.navigate(["parameter-commission"])
  }

  goToOldTablesTariff() {
    this.router.navigate(["old-tables-tariff"])
  }

  goToOldTablesCommissions() {
    this.router.navigate(["old-tables-commissions"])
  }

  descriptionAddExternal() {
    const user = this.userService.getCurrentUser();
    if (user.PerfilAcesso === 'Master') return "Cadastrar usuário"
    return "Cadastrar usuário Externo";
  }

  goToDraftList() {
    this.router.navigate(["draft-list"])
  }

  goToIneligibilityReport() {
    this.router.navigate(["ineligibility-report"])
  }

  goToConcessionairesAndPartners() {
    this.router.navigate(["concessionaires-and-partners"])
  }

  goToConcessionairesAndPartnersB2b2c() {
    this.router.navigate(["concessionaires-and-partners-b2b2c"])
  }

  goToChangeReport() {
    this.router.navigate(["change-report"])
  }

}
