export enum EPermissaoAcesso {
    CADASTRAR_NOVO_USUARIO = 'CADASTRAR_NOVO_USUARIO',
    CADASTRAR_NOVO_USUARIO_INTERNO = 'CADASTRAR_NOVO_USUARIO_INTERNO',
    EDITAR_USUARIO_INTERNO = 'EDITAR_USUARIO_INTERNO',
    INATIVAR_USUARIO_INTERNO =  'INATIVAR_USUARIO_INTERNO',
    CADASTRAR_CONCESSIONARIA = 'CADASTRAR_CONCESSIONARIA',
    GERENCIAR_PERFIL = 'GERENCIAR_PERFIL',
    ADICIONAR_USUARIOS_PERFIL = 'ADICIONAR_USUARIOS_PERFIL',
    EXTRAIR_RELATORIO_ACESSOS = 'EXTRAIR_RELATORIO_ACESSOS',
    GERENCIAR_PARAMETROS_PRODUTOS = 'GERENCIAR_PARAMETROS_PRODUTOS',
    TAXAS = 'TAXAS',
    COMISSAO = 'COMISSAO',
    CATEGORIA_PRODUTOS_AQUISICAO = 'CATEGORIA_PRODUTOS_AQUISICAO',
    PERCENTUAL_MAJORACAO_PPS = 'PERCENTUAL_MAJORACAO_PPS',
    CADASTRAR_PRODUTOS = 'CADASTRAR_PRODUTOS',
    CADASTRAR_EMPRESAS = 'CADASTRAR_EMPRESAS',
    CADASTRAR_CONCESSIONARIA_COMERCIALIZACAO = 'CADASTRAR_CONCESSIONARIA_COMERCIALIZACAO',
    CADASTRAR_NOMENCLATURA = 'CADASTRAR_NOMENCLATURA',
    EXCLUIR_NOMENCLATURA = 'EXCLUIR_NOMENCLATURA',
    CADASTRAR_MENSAGEM = 'CADASTRAR_MENSAGEM',
    GERENCIAR_MINUTA = 'GERENCIAR_MINUTA',
    CADASTRAR_COMUNICACAO_EMAIL = 'CADASTRAR_COMUNICACAO_EMAIL',
    EXTRAIR_RELATORIO_INEGIBILIDADE = 'EXTRAIR_RELATORIO_INEGIBILIDADE',
    EXTRAIR_RELATORIO_MUDANCA_STATUS = 'EXTRAIR_RELATORIO_MUDANCA_STATUS',
    EXTRAIR_RELATORIO_EXTRATO_CONTRATO = 'EXTRAIR_RELATORIO_EXTRATO_CONTRATO',
    EXTRAIR_RELATORIO_COMISSAO = 'EXTRAIR_RELATORIO_COMISSAO',
    CADASTRAR_PARAMETROS_TECNICOS = 'CADASTRAR_PARAMETROS_TECNICOS',
    CADASTRAR_SERVIDOR_EMAIL = 'CADASTRAR_SERVIDOR_EMAIL',
    CONFIGURACAO_TECNICA_WILDCARD = 'CONFIGURACAO_TECNICA_WILDCARD',
    CADASTRAR_DIRETORIO_RAIZ = 'CADASTRAR_DIRETORIO_RAIZ',
    CADASTRAR_DIRETORIO_ARQUIVO_CONTABIL = 'CADASTRAR_DIRETORIO_ARQUIVO_CONTABIL',
    CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA = 'CADASTRAR_USUARIO_EMPRESA_CONCESSIONARIA',
    CONTRATAR_PLANO = 'CONTRATAR_PLANO',
    MANTER_PLANO = 'MANTER_PLANO',
    CONSULTAR_PLANO = 'CONSULTAR_PLANO',
    DESISTIR_PLANO = 'DESISTIR_PLANO',
    CANCELAR_PLANO = 'CANCELAR_PLANO',
    GERAR_PRIMEIRA_PARCELA = 'GERAR_PRIMEIRA_PARCELA',
    PRORROGAR_PLANO = 'PRORROGAR_PLANO',
    REMITIR_BOLETO = 'REMITIR_BOLETO',
    CADASTRAR_DADOS_BANCARIOS = 'CADASTRAR_DADOS_BANCARIOS',
    CADASTRAR_EVENTOS_ROTEIROS_CONTABEIS = 'CADASTRAR_EVENTOS_ROTEIROS_CONTABEIS',
    EXTRAIR_RELATORIO_CONCILIACAO_MOVIMENTACAO = 'EXTRAIR_RELATORIO_CONCILIACAO_MOVIMENTACAO',
    EXTRAIR_RELATORIO_SEGREGACAO = 'EXTRAIR_RELATORIO_SEGREGACAO',
    EXTRAIR_RELATORIO_POSICAO_CONTABIL = 'EXTRAIR_RELATORIO_POSICAO_CONTABIL',
    EXTRAIR_RELATORIO_ESPELHO_CONTRATO = 'EXTRAIR_RELATORIO_ESPELHO_CONTRATO',
    CADASTRAR_RELACIONAMENTO_PRODUTO_MODALIDADE = 'CADASTRAR_RELACIONAMENTO_PRODUTO_MODALIDADE',
    CADASTRAR_RELACIONAMENTO_PRODUTO_FATO = 'CADASTRAR_RELACIONAMENTO_PRODUTO_FATO',
    CADASTRAR_SERVICO_BUSCA_OPERACAO = 'CADASTRAR_SERVICO_BUSCA_OPERACAO',
    CADASTRAR_SPALAVRAS_RESERVADAS = 'CADASTRAR_SPALAVRAS_RESERVADAS',
    CADASTRAR_ROTEIRO_CONTABEL = 'CADASTRAR_ROTEIRO_CONTABEL',
    CANCELAR_PLANO_AUTOMATICAMENTE = 'CANCELAR_PLANO_AUTOMATICAMENTE',
    DEVOLVER_TARIFAS_VALORES = 'DEVOLVER_TARIFAS_VALORES',
    FINALIZAR_PLANO = 'FINALIZAR_PLANO',
    EXTRAIR_RELATORIO_LIQUIDACAO = 'EXTRAIR_RELATORIO_LIQUIDACAO',
    EXTRAIR_RELATORIO_REVERSAO_LIBERACAO_VALORES = 'EXTRAIR_RELATORIO_REVERSAO_LIBERACAO_VALORES',
    EXTRAIR_RELATORIO_LIBERACAO_VALORES = 'EXTRAIR_RELATORIO_LIBERACAO_VALORES',
    EXTRAIR_RELATORIO_CONTRATOS_PENDENTES_CARTAO_CREDITO = 'EXTRAIR_RELATORIO_CONTRATOS_PENDENTES_CARTAO_CREDITO',
    GERAR_ARQUIVO_CONTABIL = 'GERAR_ARQUIVO_CONTABIL',
    LISTAR_PRODUTOS = 'LISTAR_PRODUTOS',
    LISTAR_PARAMETROS = 'LISTAR_PARAMETROS',
    HISTORICO_PARAMETROS = 'HISTORICO_PARAMETROS',
    REGULARIZAR_RESTRICAO_CREDITO= 'REGULARIZAR_RESTRICAO_CREDITO',
    EXTRAIR_RELATORIO_INELEGIBILIDADE='EXTRAIR_RELATORIO_INELEGIBILIDADE',
    EXTRATO_PLANO='EXTRATO_PLANO',
    CREDENCIAMENTO_CONCESSIONARIA = 'CREDENCIAMENTO_CONCESSIONARIA',
    SOLICITA_VALORES_PLANO_QUITADO = 'SOLICITA_VALORES_PLANO_QUITADO',
    ALTERAR_CONCESSIONARIA_RELACIONAMENTO = 'ALTERAR_CONCESSIONARIA_RELACIONAMENTO',
    CADASTRAR_PERFIL_USUARIO = 'CADASTRAR_PERFIL_USUARIO',
    CONSULTAR_CONCESSIONARIA = 'CONSULTAR_CONCESSIONARIA',
}
