<yfs-header></yfs-header>
<section class="container-content">
    <div
        *ngIf="!cancelPlanIsOpen && !editClientDataIsOpen && !editBankDataIsOpen && !proofPaymentIsOpen && !releaseValuesIsOpen && !changeRelationshipIsOpen && !planExtractIsOpen">
        <div class="flex-container">
          <div class="container-title">
              <div class="button-back">
                  <yfs-link-back text="Voltar para a tela inicial" (click)="getBack()"></yfs-link-back>
                </div>
              <h1>PLANO DO CLIENTE</h1>
          </div>
          <div *ngIf="tabInfo" class="tab-container">
            <mat-accordion>
              <mat-expansion-panel [expanded]="isHoveringRelacionamento"
              (mouseenter)="onMouseEnterRelacionamento()"
              (mouseleave)="onMouseLeaveRelacionamento()">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Canal de Relacionamento
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="tab-content">
                  <div *ngIf="tabInfo?.canalRelacionamento?.razaoSocial" class="info-container">
                    <div class="label-with-icon">
                      <mat-icon aria-hidden="false" aria-label="Ícone de razão social">business</mat-icon>
                      <span class="h1">Razão Social da Concessionária</span>
                    </div>
                    <span class="h2">{{tabInfo?.canalRelacionamento?.razaoSocial}}</span>
                  </div>

                  <div *ngIf="tabInfo?.canalRelacionamento?.email" class="info-container">
                    <div class="label-with-icon">
                      <mat-icon aria-hidden="false" aria-label="Ícone de e-mail">email</mat-icon>
                      <span class="h1">E-mail</span>
                    </div>
                    <span class="h2">{{tabInfo?.canalRelacionamento?.email}}</span>
                  </div>

                  <div *ngIf="tabInfo?.canalRelacionamento?.telefone" class="info-container">
                    <div class="label-with-icon">
                      <mat-icon aria-hidden="false" aria-label="Ícone de telefone">phone</mat-icon>
                      <span class="h1">Telefone</span>
                    </div>
                    <span class="h2">{{tabInfo?.canalRelacionamento?.telefone | telefone}}</span>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel [expanded]="isHoveringOriginacao"
              (mouseenter)="onMouseEnterOriginacao()"
              (mouseleave)="onMouseLeaveOriginacao()">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Canal de Originação
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="tab-content">
                    <div class="info-container">
                      <div class="label-with-icon">
                        <mat-icon aria-hidden="false" aria-label="Ícone de razão social">business</mat-icon>
                        <span class="h1">{{ [2, 3].includes(customerPlan?.canalOrigem ?? 1) ? 'Canal de Venda' : 'Razão Social da Concessionária' }}</span>
                      </div>
                      <span class="h2">{{ [2, 3].includes(customerPlan?.canalOrigem ?? 1) ? 'E-commerce' : tabInfo?.canalOriginacao?.razaoSocial }}</span>
                    </div>

                    <div class="info-container">
                      <div class="label-with-icon">
                        <mat-icon aria-hidden="false" aria-label="Ícone de e-mail">email</mat-icon>
                        <span class="h1">E-mail</span>
                      </div>
                      <span class="h2">{{ [2, 3].includes(customerPlan?.canalOrigem ?? 1) ? 'liberacred@yamaha-motor.com.br' : tabInfo?.canalOriginacao?.email }}</span>
                    </div>

                    <div class="info-container">
                      <div class="label-with-icon">
                        <mat-icon aria-hidden="false" aria-label="Ícone de telefone">phone</mat-icon>
                        <span class="h1">Telefone</span>
                      </div>
                      <span class="h2">{{ [2, 3].includes(customerPlan?.canalOrigem ?? 1) ? '(11) 2088-7700' : (tabInfo?.canalOriginacao?.telefone | telefone) }}</span>
                    </div>
                  </div>
                </mat-expansion-panel>
            </mat-accordion>
          </div>
          </div>
          <div class="flex-space-between-column">
            <div>
                <h2 style="color: var(--color-primary-2);">
                    {{customerPlan?.nomeCliente}}
                    <span class="cpf-label">
                        {{customerPlan?.cpf | cpf }}
                    </span>
                </h2>
                <div class="card-content-contract">
                    <mat-card class="mini-card-status">
                        <span style="display: flex;">
                            <mat-icon aria-hidden="false" aria-label="Ícone de status" [style.color]="getIconColor()"
                                class="material-symbols-outlined" [fontIcon]="getIcon()"></mat-icon>
                        </span>
                        <span class="h2" style="margin-left: 10px;">{{getStatusDescription()}}</span>
                    </mat-card>
                    <mat-card class="mini-card-secundary">
                        <span class="h1">Valor do contrato</span>
                        <span class="h2">{{customerPlan?.valorContrato | currency}}</span>
                    </mat-card>
                    <mat-card class="mini-card-secundary">
                        <span class="h1">Data de adesão</span>
                        <span class="h2">{{customerPlan?.dataAdesao || '-'}}</span>
                    </mat-card>
                    <mat-card *ngIf="customerPlan?.statusContratoNumero === 10" class="mini-card-secundary">
                        <span class="h1">Data de desistência</span>
                        <span class="h2">{{customerPlan?.dataCancelamento | dateConvert }}</span>
                    </mat-card>
                    <mat-card class="mini-card-secundary">
                        <span class="h1">Contrato</span>
                        <span class="h2">{{customerPlan?.numeroContrato || '-'}}</span>
                    </mat-card>
                </div>
            </div>
            <div>
                <h2>Status de elegibilidade</h2>
                <div class="card-content-elegibility">
                    <mat-card class="mini-card">
                        <span class="h1">Recargas</span>
                        <span class="h2">{{ customerPlan?.aditado ? getRecargasToProrrogacao() : customerPlan?.statusElegibilidade?.recargas}}</span>
                    </mat-card>
                    <mat-card class="mini-card">
                        <span class="h1">Pontuais</span>
                        <span
                        class="h2">{{customerPlan?.aditado ? getPontuaisToProrrogacao() : customerPlan?.statusElegibilidade?.pontuais}}/{{customerPlan?.aditado ? getRecargasToProrrogacao() : customerPlan?.statusElegibilidade?.recargas}}</span>
                    </mat-card>
                    <mat-card class="mini-card">
                        <span class="h1">Reserva</span>
                        <span class="h2">{{customerPlan?.statusElegibilidade?.reserva | currency}}</span>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="selo-elegibilidade">
            <yfs-seal-eligibility
            [punctuality]=" customerPlan?.pontualidade"
            [discharge]="customerPlan?.quitacao"
            [restriction]="customerPlan?.restricaoCredito"
            [showDetailButton]="true"
            [showProofPaymentButton]="proofPaymentIsEnabled"
            (detailClick)="onDetailClick()"
            (proofClick)="openProofPayment()"
            ></yfs-seal-eligibility>
          </div>
          <div style="margin-top: 32px;">
              <mat-card style="box-shadow: 0px 1px 4px #00000052;">
                  <mat-card-content>
                    <div class="message-document" *ngIf="verificarAssinaturaEletronica() && this.customerPlan?.statusContratoNumero !== 15 && !isCanceladoOrFinalizado()">
                      <mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols" fontIcon="info"> </mat-icon>
                      <span>Contrato pendente de assinatura eletrônica do termo de adesão. Verifique o e-mail, whatsapp ou sms cadastrado e
                          <b>conclua a assinatura até {{ obterDataExpiracao() | date : 'dd/MM/yyyy' }} </b> ou o contrato será cancelado automaticamente.
                      </span>
                    </div>

                    <div class="message-document"
                        *ngIf="verificarAssinaturaManual() && this.customerPlan?.statusContratoNumero !== 15 && !termoAssinado">
                        <mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
                            fontIcon="info"> </mat-icon>
                        <span>Este cliente possui o ”Termo de adesão ao Liberacred” pendente de assinatura.</span>
                    </div>

                    <div class="message-document" *ngIf="verificarAssinaturaEletronicaProrrogacao()">
                      <mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols" fontIcon="info"> </mat-icon>
                      <span>Este cliente possui o ”Termo de prorrogação” pendente de assinatura.</span>
                    </div>
                    <div class="content-buttons">
                        <div class="mobile-buttons">
                            <yfs-button type="secondary" size="small" [fullWidth]="isFullWidth" class="button-width" state="enabled" label="AÇÕES DO PLANO"
                                icon="add_circle" [matMenuTriggerFor]="menuAcoes"></yfs-button>
                            <mat-menu #menuAcoes="matMenu">
                                <button (click)="openEditClientData()" mat-menu-item>
                                    <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined"
                                        fontIcon="manage_accounts"></mat-icon>
                                    <span class="label-menu">DADOS CADASTRAIS</span>
                                </button>
                                <button (click)="openEditBankData()" mat-menu-item>
                                    <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined"
                                        fontIcon="account_balance"></mat-icon>
                                    <span class="label-menu">DADOS BANCÁRIOS</span>
                                </button>
                                <div *appHasAuthorization="proofPaymentAlowedPermissions">
                                    <button *ngIf="proofPaymentIsEnabled" (click)="openProofPayment()" mat-menu-item>
                                        <mat-icon aria-hidden="false" aria-label="Icon"
                                            class="material-symbols-outlined" fontIcon="upload"></mat-icon>
                                        <span class="label-menu">ENVIAR COMPROVANTE DE QUITAÇÃO</span>
                                    </button>
                                </div>
                                <div *appHasAuthorization="changeRelationshipAlowedPermissions">
                                    <button (click)="openChangeRelationship()" mat-menu-item class="icon-button">
                                        <mat-icon aria-hidden="false" aria-label="Icon"
                                            class="material-symbols-outlined icon" fontIcon="store"></mat-icon>
                                        <span class="label-menu">ALTERAR CONCESSIONÁRIA DE RELACIONAMENTO</span>
                                    </button>
                                </div>
                                <div *appHasAuthorization="amountAlowedPermissions">
                                    <button *ngIf="verifyAmountPermission()" (click)="openReleaseValues()"
                                        mat-menu-item>
                                        <mat-icon aria-hidden="false" aria-label="Icon"
                                            class="material-symbols-outlined" fontIcon="attach_money"></mat-icon>
                                        <span class="label-menu">LIBERAÇÃO DE VALORES</span>
                                    </button>
                                </div>
                                <div *appHasAuthorization="extractAlowedPermissions">
                                    <button
                                        *ngIf="this.customerPlan?.statusContratoNumero !== 0 && this.customerPlan?.statusContratoNumero !== 4"
                                        (click)="openPlanExtract()" mat-menu-item>
                                        <mat-icon aria-hidden="false" aria-label="Icon"
                                            class="material-symbols-outlined" fontIcon="receipt_long"></mat-icon>
                                        <span class="label-menu">EXTRATO DO PLANO</span>
                                    </button>
                                </div>
                                <div>
                                  <button *ngIf="this.customerPlan?.podeProrrogar" (click)="openExtension()" mat-menu-item>
                                    <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined" fontIcon="receipt_long"></mat-icon>
                                    <span class="label-menu">PRORROGAR PLANO</span>
                                  </button>
                                  </div>
                            </mat-menu>
                            <yfs-button class="download-button" [fullWidth]="isFullWidth" type="secondary" state="enabled" size="small"
                                label="DOWNLOAD DE DOCUMENTOS" icon="download" [matMenuTriggerFor]="menu"></yfs-button>
                            <mat-menu #menu="matMenu" class="outro">
                                <button (click)="getRegulamento()" mat-menu-item>
                                    <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined"
                                        fontIcon="download"></mat-icon>
                                    <span class="label-menu">REGULAMENTO</span>
                                </button>
                                <button
                                    *ngIf="customerPlan?.statusContratoNumero !== 5 && !verificarAssinaturaEletronica()"
                                    (click)="getTermoAdesao(false)" mat-menu-item>
                                    <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined"
                                        fontIcon="download"></mat-icon>
                                    <span class="label-menu">TERMO DE ADESÃO</span>
                                </button>
                                <button *ngIf="!verificarAssinaturaEletronica()" (click)="getTermoAdesao(true)"
                                    mat-menu-item>
                                    <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined"
                                        fontIcon="download"></mat-icon>
                                    <span class="label-menu">TERMO DE ADESÃO ASSINADO</span>
                                </button>
                                  <button *ngIf="verificarAssinaturaProrrogacao() && (customerPlan?.statusContratoNumero === 18 || customerPlan?.statusContratoNumero === 19)"
                                    (click)="getTermoProrrogacao(true)" mat-menu-item>
                                    <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined" fontIcon="download"></mat-icon>
                                    <span class="label-menu">TERMO DE PRORROGAÇÃO ASSINADO</span>
                                  </button>

                                  <button *ngIf="!verificarAssinaturaProrrogacao() && (customerPlan?.statusContratoNumero === 18 || customerPlan?.statusContratoNumero === 19)"
                                          (click)="getTermoProrrogacao(false)" mat-menu-item>
                                    <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined" fontIcon="download"></mat-icon>
                                    <span class="label-menu">TERMO DE PRORROGAÇÃO</span>
                                  </button>
                                <div *appHasAuthorization="cancelAllowedPermissions">
                                    <button *ngIf="customerPlan?.statusContratoNumero === 10"
                                        (click)="getTermoCancelamento(false)" mat-menu-item>
                                        <mat-icon aria-hidden="false" aria-label="Icon"
                                            class="material-symbols-outlined" fontIcon="download"></mat-icon>
                                        <span class="label-menu">TERMO DE CANCELAMENTO</span>
                                    </button>
                                    <button
                                        *ngIf="customerPlan?.statusContratoNumero === 10 || customerPlan?.statusContratoNumero === 5"
                                        (click)="getTermoCancelamento(true)" mat-menu-item>
                                        <mat-icon aria-hidden="false" aria-label="Icon"
                                            class="material-symbols-outlined" fontIcon="download"></mat-icon>
                                        <span class="label-menu">TERMO DE CANCELAMENTO ASSINADO</span>
                                    </button>
                                </div>
                            </mat-menu>
                        </div>
                        <div
                            *ngIf="!assinaturaEletronicaNaoAssinada() && customerPlan?.statusContratoNumero !== 5 && this.customerPlan?.statusContratoNumero !== 15 && !assinaturaEletronicaHabilitada">
                            <yfs-button type="primary" size="small" [fullWidth]="isFullWidth" state="enabled" label="UPLOAD DE DOCUMENTOS"
                                icon="upload" [matMenuTriggerFor]="menu2"></yfs-button>
                            <mat-menu #menu2="matMenu">
                                <button mat-menu-item (click)="openDialogUploadMinuta()">
                                    <mat-icon aria-hidden="false" aria-label="Icon" class="material-symbols-outlined"
                                        fontIcon="upload"></mat-icon>
                                    <span class="label-menu">TERMO DE ADESÃO</span>
                                </button>
                                <div *appHasAuthorization="cancelAllowedPermissions">
                                    <button *ngIf="customerPlan?.statusContratoNumero === 10"
                                        (click)="openDialogUpload()" mat-menu-item>
                                        <mat-icon aria-hidden="false" aria-label="Icon"
                                            class="material-symbols-outlined" fontIcon="upload"></mat-icon>
                                        <span class="label-menu">TERMO DE CANCELAMENTO</span>
                                    </button>
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="scrollable-table-container" style="margin-top: 10px;">
                        <div *ngIf="showEmpty()"
                            class="content-empty">
                            <img src="assets/svg/empty-document.svg" alt="documento vazio" height="64" width="64">
                            <div>
                                <span><b>Ainda não há boletos gerados.</b></span>
                                <span>Eles somente serão gerados após a assinatura eletrônica do termo de adesão.</span>
                            </div>
                        </div>
                        <table *ngIf="showInstallmentTable()" mat-table [dataSource]="dataSource2"
                            #secondTable #secondTableSort="matSort" matSort
                            (matSortChange)="announceSortChange2($event)">

                            <ng-container matColumnDef="recarga">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by number">
                                    Recarga
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.recarga}}</td>
                            </ng-container>

                            <ng-container matColumnDef="valor">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by valor">
                                    Valor
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.valor | currency}}</td>
                            </ng-container>

                            <ng-container matColumnDef="dataLimitePagamento">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by dataLimitePagamento">
                                    Limite para Pagamento
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.dataLimitePagamento}}</td>
                            </ng-container>

                            <ng-container matColumnDef="dataPrimeiroVencimento">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by dataPrimeiroVencimento">
                                    Data de vencimento
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="td-icon">
                                        <span> {{element.dataPrimeiroVencimento}}</span>
                                        <div class="align-right">
                                            <yfs-tooltip [tooltipText]="getTooltipText(element)" [wrap]="true"><mat-icon
                                                    style="position: static; font-size: 16px; margin-left: 13px; margin-top: -7px;"
                                                    *ngIf="showDate(element.dataAtualizacao)" aria-hidden="false"
                                                    aria-label="Ícone de informação" class="material-symbols"
                                                    fontIcon="info"> </mat-icon></yfs-tooltip>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dataPagamento">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by dataPagamento">
                                    Data de pagamento
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.dataPagamento}}</td>
                            </ng-container>

                            <ng-container matColumnDef="pontual">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by pontual">
                                    Pontualidade
                                </th>
                                <td mat-cell *matCellDef="let element"
                                    [style.color]="getPontualidadeColor(element.pontual)">
                                    <mat-icon aria-hidden="false" aria-label="Ícone" class="material-symbols-outlined"
                                        [fontIcon]="getPontualidadeIcon(element.pontual)"></mat-icon>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by statusParcelaDescricao">
                                    Status
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{getStatusName(element.statusParcelaDescricao)}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="boleto">
                                <th mat-header-cell *matHeaderCellDef>
                                    Boleto
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="button-download-boleto"
                                        *ngIf="element.statusParcelaDescricao === 'EmAberto' && this.customerPlan?.statusContratoNumero !== 15"
                                        [matMenuTriggerFor]="menuBoleto">
                                        <mat-icon aria-hidden="false" aria-label="Ícone de boleto"
                                            class="material-symbols-outlined icon-boleto"
                                            fontIcon="receipt_long"></mat-icon>
                                        <span>BOLETO</span>
                                    </div>
                                    <div class="button-more-info" *ngIf="this.customerPlan?.statusContratoNumero === 15"
                                        (click)="openModalTermCancel()">
                                        <mat-icon aria-hidden="false" aria-label="Ícone de boleto"
                                            class="material-symbols icon-more-info" fontIcon="info"></mat-icon>
                                        <span>SAIBA MAIS</span>
                                    </div>
                                    <mat-menu #menuBoleto="matMenu">
                                        <button
                                            (click)="handleDownloadClick(element.idParcelaBoleto, element.recarga, element.boletoExpirado)"
                                            mat-menu-item>
                                            <mat-icon aria-hidden="false" aria-label="Ícone de download do boleto"
                                                class="material-symbols-outlined" fontIcon="download"></mat-icon>
                                            <span class="label-menu">BAIXAR BOLETO</span>
                                        </button>
                                        <button
                                            (click)="handleSendEmailClick(element.idParcelaBoleto, element.boletoExpirado)"
                                            mat-menu-item>
                                            <mat-icon aria-hidden="false" aria-label="Ícone de email"
                                                class="material-symbols-outlined" fontIcon="mail"></mat-icon>
                                            <span class="label-menu">ENVIAR POR E-MAIL</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                        </table>
                        <div *ngIf="!verificarAssinaturaEletronica() && showGenerateButton"
                            class="genarate-button-container">
                            <yfs-button data-test="button-boleto"
                                [label]="isBoletoBeingGenerated ? 'GERANDO PARCELA' : 'GERAR PARCELA'"
                                [press]="gerarBoleto" type="primary" size="small"
                                [state]="isBoletoBeingGenerated ? 'disabled' : 'enabled'"
                                [loadingIndicator]="isBoletoBeingGenerated ? true : false">
                            </yfs-button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <div class="cancel-content" *appHasAuthorization="cancelAllowedPermissions">
                <yfs-button *ngIf="customerPlan?.podeCancelar" type="secondary" state="enabled" size="small"
                    label="CANCELAR PLANO" color="red" (click)="handleCancelButtonPress()">
                </yfs-button>
            </div>
        </div>
    </div>
    <yfs-client-data *ngIf="editClientDataIsOpen" (currentFlowChanged)="closeEditClientData()"
        [cpfSelected]="customerPlan?.cpf" origin="clientPlan"></yfs-client-data>
    <yfs-bank-data *ngIf="editBankDataIsOpen" (currentFlowChanged)="closeEditBankData($event)"
        [cpfSelected]="customerPlan?.cpf" [userName]="customerPlan?.nomeCliente"
        [concessionariaValue]="tabInfo?.canalRelacionamento?.idConcessionaria" [dadosBancariosData]="customerPlan?.dadosBancarios"
        [idContrato]="customerPlan?.idContrato" (bankDataChanged)="onDataChanged()">
    </yfs-bank-data>
    <yfs-release-paid-amounts *ngIf="releaseValuesIsOpen" (closeReleaseValues)="closeReleaseValues()"
        (chanceStatus)="changeStatus($event)" [idContrato]="customerPlan?.idContrato"
        [statusContratoNumero]="customerPlan?.statusContratoNumero" [cpfClient]="customerPlan?.cpf"
        [userName]="customerPlan?.nomeCliente" [concessionariaValue]="tabInfo?.canalRelacionamento?.idConcessionaria"
        [dadosBancariosData]="customerPlan?.dadosBancarios"
        [emailCliente]="customerPlan?.emailCliente"></yfs-release-paid-amounts>
    <yfs-proof-payment *ngIf="proofPaymentIsOpen" (currentFlowChanged)="closeProofPayment()"
        [idContrato]="customerPlan?.idContrato"></yfs-proof-payment>
    <yfs-change-relationship *ngIf="changeRelationshipIsOpen" (currentFlowChanged)="closeChangeRelationship(customerPlan?.idContrato)"
        (closeChangeRelationship)="closeChangeRelationship(customerPlan?.idContrato)" [idContrato]="customerPlan?.idContrato"
        [concessionariaValue]="tabInfo?.canalRelacionamento?.idConcessionaria"></yfs-change-relationship>
    <yfs-plan-extract *ngIf="planExtractIsOpen" (closePlanExtract)="closePlanExtract()"
        [idContrato]="customerPlan?.idContrato" [cpfClient]="customerPlan?.cpf"
        [userName]="customerPlan?.nomeCliente"></yfs-plan-extract>

    <yfs-cancel-plan *ngIf="cancelPlanIsOpen" (closeCancelPlan)="closeCancelPlan()"
        (chanceStatus)="changeStatus($event)" [idContrato]="customerPlan?.idContrato"
        [statusContratoNumero]="customerPlan?.statusContratoNumero" [cpfClient]="customerPlan?.cpf"
        [userName]="customerPlan?.nomeCliente" [concessionariaValue]="tabInfo?.canalRelacionamento?.idConcessionaria"
        [dadosBancariosData]="customerPlan?.dadosBancarios" [emailCliente]="customerPlan?.emailCliente"
        [telefoneCliente]="customerPlan?.telefoneCliente"></yfs-cancel-plan>
        <yfs-extension  (openCancelPlan)="openCancelPlanComponent()" *ngIf="extensionIsOpen" (currentFlowChanged)="closeExtension()" [userName]="customerPlan?.nomeCliente" [podeCancelar]="customerPlan?.podeCancelar" [statusContratoNumero]="customerPlan?.statusContratoNumero" [idContrato]="customerPlan?.idContrato"></yfs-extension>
</section>

<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
