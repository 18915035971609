import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment.general';
import { Observable } from 'rxjs';
import { IPaymentForms, IProductView } from '../models/product-view.interface';
import { IProduct } from '../models/products.interface';
import { ICategory } from '../models/category.interface';
import { IAddressResponse, IProposal, IRegister } from '../models/register.interface';
import { ICancelPlan } from '../models/cancel-plan.interface';


@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {
  private urlApi = environment.urlApi
  // private urlApi = "https://localhost:5000/api"

  constructor(
    private http: HttpClient
  ) { }

  public getCategory() {
    return this.http.get<ICategory>(`${this.urlApi}/v1/produtos/categorias`);
  }

  public getProductsActive(id: number) {
    return this.http.get<any>(`${this.urlApi}/v1/produtos/ativos?IdCategoriaExibicao=${id}`);
  }

  public getProductById(id: number): Observable<IProductView> {
    return this.http.get<IProductView>(`${this.urlApi}/v1/planos/simular?IdProduto=${id}`)
  }

  public getPaymentForm(): Observable<IPaymentForms[]> {
    return this.http.get<IPaymentForms[]>(`${this.urlApi}/formas-pagamento`)
  }

  public validateCpf(cpf: any): Observable<IRegister> {
    return this.http.get<IRegister>(`${this.urlApi}/clientes/sem-contrato`, { headers: { cpf: cpf } })
  }

  public getUser(cpf: any): Observable<IRegister> {
    return this.http.get<IRegister>(`${this.urlApi}/clientes`, { headers: { cpf: cpf } })
  }

  public getAddress(cep: string): Observable<any> {
    return this.http.get<IAddressResponse>(`${this.urlApi}/enderecos?Cep=${cep}`, { observe: 'response' });
  }

  public postContratoManual(proposal?: IProposal): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/contratos/manual`, proposal);
  }

  public postContratoDigital(proposal?: IProposal): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.urlApi}/v1/contratos/digital`, proposal, { observe: 'response' });
  }

  public postMinuta(cpfCliente: string | null | undefined, minutaAssinada: string): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/minutas`, { cpfCliente, minutaAssinada });
  }

  public postAccounting(dataMovimentacao: string): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/contabilidade/arquivo-contabil`, { dataMovimentacao }, { observe: 'response' });
  }

    public getCommissions(DataInicio: string, DataFinal: string, TipoRelatorio: string, FormatoArquivo: string) {
      return this.http.get(`${this.urlApi}/v1/relatorios/comissao?DataInicio=${DataInicio}&DataFinal=${DataFinal}&TipoRelatorio=${TipoRelatorio}&FormatoArquivo=${FormatoArquivo}`,
        { responseType: 'blob', observe: 'response' });
    }

  public getSegregationReport(dataInicio: string | null, periodAnual: boolean | null = false, reportTypeAnalitico: boolean | null = true) {
    return this.http.get(`${this.urlApi}/v1/relatorios/segregacao?dataInicio=${dataInicio}&anual=${periodAnual}&analitico=${reportTypeAnalitico}`,
      { responseType: 'blob', observe: 'response' });
  }

  public getPositionReport(DataBase: string, NumeroContrato: string, Cpf: string | null, Status: number[], reportFormat: string = 'xlsx') {
    let queryParams = `DataBase=${DataBase}`;

    if (NumeroContrato !== null && NumeroContrato !== '') {
      queryParams += `&NumeroContrato=${NumeroContrato}`;
    }

    if (Cpf !== null && Cpf !== '') {
      queryParams += `&Cpf=${Cpf}`;
    }

    if (Status && Status.length > 0) {
      Status.forEach((status) => {
        queryParams += `&Status=${status}`;
      });
    }
    queryParams += `&FormatoArquivo=${reportFormat}`

    return this.http.get(`${this.urlApi}/v1/relatorios/posicao?${queryParams}`, { responseType: 'blob', observe: 'response' });
  }

  public getContractedPlans(searchString?: string, dataInicio?: string, dataFim?: string, status?: number[], isAssinaturaPendente?: boolean, idsConcessionarias?: Array<number>, origem?: string, page?: number, pageSize?: number) {
    return this.http.get<any>(`${this.urlApi}/v1/contratos/?Concessionarias=${idsConcessionarias?.join('&Concessionarias=')}&IsAssinaturaPendente=${isAssinaturaPendente}&Query=${searchString}&DataInicioContratacao=${dataInicio}&DataFimContratacao=${dataFim}&${status?.map(s => `StatusContrato=${s}`).join('&')}&CanalOrigem=${origem}&PageNumber=${page}&PageSize=${pageSize}`);
  }

  public cancelPlan(bankDatas: ICancelPlan) {
    return this.http.put<any>(`${this.urlApi}/v1/planos/cancelar`, bankDatas)
  }

  public getBanks() {
    return this.http.get<any>(`${this.urlApi}/bancos`);
  }

  public getTermoCancelamento(payLoad: any): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/termo-cancelamento?IdContrato=${payLoad.idContrato}&Assinado=${payLoad.termoAssinado}`, { observe: 'response' });
  }

  public sendTermo(cpfCliente?: string, termoCancelamentoAssinado?: string) {
    return this.http.post<any>(`${this.urlApi}/termo-cancelamento`, { cpfCliente, termoCancelamentoAssinado });
  }

  public getValueToReceive(idContrato?: number) {
    return this.http.get<any>(`${this.urlApi}/v1/planos/cancelar/valores-restituicao?IdContrato=${idContrato}`)
  }

  public getValueToGive(idContrato?: number) {
    return this.http.get<any>(`${this.urlApi}/v1/planos/saldo-liberacred?IdContrato=${idContrato}`)
  }

  public getTermoAdesao(idContrato?: number, assinada?: boolean): Observable<any> {
    return this.http.get(`${this.urlApi}/minutas?IdContrato=${idContrato}&Assinada=${assinada}`, { observe: 'response' })
  }

  public getRegulamento(idContrato?: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/regulamentos?IdContrato=${idContrato}`, { observe: 'response' })
  }

  public postParcela(idContrato?: number, numeroParcela?: number): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/parcelas`, { idContrato, numeroParcela }, { observe: 'response' })
  }

  public getReleaseValuesReport(objSend: any): Observable<any> {
    let query = `dataInicio=${objSend.dataInicial ?? ''}&dataFinal=${objSend.dataFinal ?? ''}&NumeroContrato=${objSend.contrato ?? ''}&ChkTodoPeriodo=${objSend.chkTodoPeriodo}`
    if (objSend.situacao && objSend.situacao.length > 0) {
      objSend.situacao.forEach((situacao: string) => {
        query += `&Situacao=${situacao}`;
      });
    }
    query += `&formatoArquivo=${objSend.formatoArquivo}`;
    return this.http.get(
      `${this.urlApi}/v1/relatorios/cancelamento?${query}`,
      { responseType: 'blob', observe: 'response' }
    );
  }

  public getConcessionaria(idConcessionaria?: number, status?: any, tipo?: any, filtroExternalUser?: boolean) {
    return this.http.get<any>(`${this.urlApi}/v1/concessionarias?IdConcessionaria=${idConcessionaria}&Status=${status?.join('&Status=')}&Tipo=${tipo}&FiltroUsuarioExterno=${filtroExternalUser}`)
  }

  public getExtractReport(objSend: any): Observable<HttpResponse<Blob>> {
    let query = `Campo=${objSend.campo}&ValorCampo=${objSend.valorCampo}&DataInicial=${objSend.dataInicial}&DataFinal=${objSend.dataFinal}&FormatoArquivo=${objSend.formatoArquivo}`
    return this.http.get(`${this.urlApi}/v1/relatorios/extrato-contrato?${query}`, { responseType: 'blob', observe: 'response' });
  }

  public updateClientData(clientData: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/clientes`, clientData)
  }

  public getReleaseValues(searchValue: string, tipoLiberacaoValue: string) {
    return this.http.get<any>(`${this.urlApi}/v1/liberacao-valores/contratos?Filtro=${searchValue}&TipoLiberacao=${tipoLiberacaoValue}`)
  }

  public getReleaseValuesDetails(contrato: number | null): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/liberacao-valores/detalhes/mensalidades?IdContrato=${contrato}`, { observe: 'response' })
  }

  public postReleaseValues(NumeroContrato: string): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/liberacao-valores/mensalidades`, { NumeroContrato }, { observe: 'response' })
  }

  public getRefundRate(contrato: number | null): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/liberacao-valores/detalhes/tarifas?IdContrato=${contrato}`, { observe: 'response' })
  }

  public postRefundRate(numeroContrato: any, idsContratoTarifas: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/liberacao-valores/tarifas`, { numeroContrato, idsContratoTarifas }, { observe: 'response' })
  }

  public postRefundCancel(numeroContrato: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/liberacao-valores/reverter`, { numeroContrato }, { observe: 'response' })
  }

  public updateBankData(bankPayload: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/dados-bancarios`, bankPayload)
  }

  public addBankData(bankPayload: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/dados-bancarios`, bankPayload)
  }

  public getMessage(): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/comunicacoes/templates-email/parametros`)
  }

  public postNewMessage(newMessage: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/comunicacoes/templates-email`, newMessage)
  }

  public updateMessage(message: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/comunicacoes/templates-email`, message)
  }

  public deleteNewMessage(idTemplate: any): Observable<any> {
    return this.http.delete<any>(`${this.urlApi}/comunicacoes/templates-email/${idTemplate}`)
  }

  public getComunication(page: number, pageSize: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/comunicacoes/templates-email?PageNumber=${page}&PageSize=${pageSize}`, { observe: 'response' })
  }

  public getBankData(idContrato?: number, cpfCnpj?: string, categoriaTerceiro?: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/dados-bancarios?IdContrato=${idContrato}&CpfCnpj=${cpfCnpj}&CategoriaTerceiro=${categoriaTerceiro}`)
  }

  public getDocuments(idContrato?: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/reenvio-documentos/documentos-disponiveis?IdContrato=${idContrato}`, { observe: 'response' })
  }

  public postReenvioDocumentos(idContrato: any, idsTermosLiberacred: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/reenvio-documentos/`, { idContrato, idsTermosLiberacred }, { observe: 'response' })
  }

  public postAtualizarEmail(cpf: any, novoEmail: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/reenvio-documentos/email-cliente`, { cpf, novoEmail }, { observe: 'response' })
  }

  public getHistoric(idContrato?: number, page?: number, pageSize?: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/reenvio-documentos/historicos?IdContrato=${idContrato}&PageNumber=${page}&PageSize=${pageSize}`)
  }

  public getClientPlanInfo(): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/contratos/cliente`, { observe: 'response' })
  }

  public resendEmail(payload: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/reenvio-documentos/reenvio-email`, payload)
  }

  public getRegistration(user?: any, tipoUsuario?: boolean): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/usuarios/matricula?User=${user}&Externo=${tipoUsuario}`, { observe: 'response' })
  }

  public createInternalUser(usuario: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/usuarios`, usuario)
  }

  public getUsers(userName?: string, idsPerfilAcesso?: Array<number>, idsTipoUsuario?: Array<number>, page?: number, pageSize?: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/usuarios?Nome=${userName}&PerfilAcesso=${idsPerfilAcesso?.join('&PerfilAcesso=')}&Tipo=${idsTipoUsuario?.join('&Tipo=')}&PageNumber=${page}&PageSize=${pageSize}`)
  }

  public getUserEdit(login: string, page: number, pageSize: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/usuarios?Login=${login}&PageNumber=${page}&PageSize=${pageSize}`)
  }

  public patchEditUser(usuario: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/v1/usuarios/atualizar`, usuario, { observe: 'response' })
  }

  public activeOrInactiveUser(inactiveUserSelected: any): Observable<any> {
    return this.http.patch<any>(`${this.urlApi}/v1/usuarios/desabilitar`, inactiveUserSelected)
  }

  public getConcessionarias(filtroExternalUser?: boolean) {
    return this.http.get<any>(`${this.urlApi}/v1/concessionarias?FiltroUsuarioExterno=${filtroExternalUser}`)
  }

  public createExternalUser(usuario: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/usuarios`, usuario)
  }

  public getProducts(nomeProduto?: string, idsCategoria?: Array<number>, idsStatus?: Array<number>, page?: number, pageSize?: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/produtos?Modelo=${nomeProduto}&Categorias=${idsCategoria?.join('&Categorias=')}&Situacoes=${idsStatus?.join('&Situacoes=')}&PageNumber=${page}&PageSize=${pageSize}`)
  }

  public getProduct(idProduto: number) {
    return this.http.get<any>(`${this.urlApi}/v1/produtos/${idProduto}`);
  }

  public updateProduct(product: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/v1/produtos`, product)
  }

  public getParameterTariff() {
    return this.http.get<any>(`${this.urlApi}/v1/parametros-tarifas`);
  }

  public updateParameterTariff(parameterTariff: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/v1/parametros-tarifas`, parameterTariff)
  }

  public getHistoricParameterTariff(id?: string, dataInicial?: string, dataFinal?: string, vigente?: boolean, page?: number, pageSize?: number, categoria?: number[]) {
    return this.http.get<any>(`${this.urlApi}/v1/parametros-tarifas/historico?Id=${id}&DataVigenciaInicio=${dataInicial}&DataVigenciaFim=${dataFinal}&Vigente=${vigente}&PageNumber=${page}&PageSize=${pageSize}&Categoria=${categoria}`);
  }

  public getParameterCommission() {
    return this.http.get<any>(`${this.urlApi}/v1/parametros-comissao`);
  }

  public updateParameterCommission(parameterCommission: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/v1/parametros-comissao`, parameterCommission)
  }

  public getProductAndCategory() {
    return this.http.get<any>(`${this.urlApi}/v2/produtos/ativos`);
  }

  public getInstallment(idContrato?: number, numeroParcela?: number): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/parcelas`, { idContrato, numeroParcela }, { observe: 'response' })
  }

  public postNewMinutaTemplate(newMinuta: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/template-minuta`, newMinuta)
  }

  public updateMinutaTemplate(minuta: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/comunicacoes/templates-minuta`, minuta)
  }

  public getConcessionariasIntegracao(search?: string, idsTipo?: Array<number>, idsGrupo ?: Array<number>,idsStatus?: Array<number>, page?: number, pageSize?: number): Observable<any> {
      return this.http.get<any>(`${this.urlApi}/v1/concessionarias/credenciamento?GenericFilter=${search}&Tipo=${idsTipo?.join('&Tipo=')}&CodigosGruposFinanceiros=${idsGrupo?.join('&CodigosGruposFinanceiros=')}&Status=${idsStatus?.join('&Status=')}&PageNumber=${page}&PageSize=${pageSize}`)
  }

  public getDraft(page: number, pageSize: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/minutas/?PageNumber=${page}&PageSize=${pageSize}`, { observe: 'response' })
  }

  public getHistoricParameterCommissions(id?: string, dataInicial?: string, dataFinal?: string, vigente?: boolean, page?: number, pageSize?: number, categorias?: number[]) {
    return this.http.get<any>(`${this.urlApi}/v1/parametros-comissao/historico?Id=${id}&DataVigenciaInicio=${dataInicial}&DataVigenciaFim=${dataFinal}&Vigente=${vigente}&PageNumber=${page}&PageSize=${pageSize}&Categoria=${categorias}`);
  }

  public getTemplatesMinuta(nomeTemplate?: string, page?: number, pageSize?: number, dataInicio?: string, idTipoMinuta?: number[]): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/template-minuta?Nomenclatura=${nomeTemplate}&DataInicio=${dataInicio}&IdsTipoMinuta=${idTipoMinuta?.join('&IdsTipoMinuta=')}&PageNumber=${page}&PageSize=${pageSize}`)
  }

  public getTipoMinuta() {
    return this.http.get<any>(`${this.urlApi}/v1/template-minuta/tipo-minuta`);
  }

  public getWildcards() {
    return this.http.get<any>(`${this.urlApi}/comunicacoes/templates-email/parametros`);
  }

  public releasePaidAmounts(dadosBancarios: any, idContrato: any, statusContrato: any, formaEnvioDocumento: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/v1/planos/quitado/resgatar`, { dadosBancarios, idContrato, statusContrato, formaEnvioDocumento }, { observe: 'response' })
  }

  public postProofPayment(proofPayment: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/comprovante-quitacao/inserir-comprovante-quitacao`, proofPayment)
  }

  public updateProofPayment(proofPayment: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/v1/comprovante-quitacao/atualizar-comprovante-quitacao`, proofPayment)
  }

  public getProofPayment(idContrato?: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/comprovante-quitacao?IdContrato=${idContrato}`)
  }

  public getInelibilityReportDetail(idContrato: number) {
    return this.http.get<any>(`${this.urlApi}/v1/relatorios/inelegibilidade/detalhes/${idContrato}`)
  }

  public getIneligibilityReport(dataInicio: string, dataFim: string, idsCanalRelacionamento?: Array<number>): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/relatorios/inelegibilidade?DataInicial=${dataInicio}&DataFinal=${dataFim}&Canal=${idsCanalRelacionamento?.join('&Canal=')}`, { observe: 'response' })
  }

  public refundRate(id: any): Observable<any> {
    return this.http.patch<any>(`${this.urlApi}/v1/liberacao-valores/${id}`, null, { observe: 'response' })
  }

  public credenciar(idConcessionaria: any, tipo: number): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/concessionarias/credenciar`, { idConcessionaria, tipo })
  }

  public getExtract(dataInicio?: string | null, dataFim?: string | null, idContrato?: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/planos/extrato-pagamento?IdContrato=${idContrato}&DataInicial=${dataInicio}&DataFinal=${dataFim}`, { observe: 'response' })
  }

  public getPerfilAcesso() {
    return this.http.get<any>(`${this.urlApi}/v1/perfil-acesso`);
  }

  public getLinkedPlan(idConcessionaria?: number, descredenciar?: boolean): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/concessionarias/existe-plano?IdConcessionaria=${idConcessionaria}&Descredenciar=${descredenciar}`, { observe: 'response' })
  }

  public descredenciar(idConcessionaria: any, idConcessionariaDestino?: number): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/concessionarias/descredenciar`, { idConcessionaria, idConcessionariaDestino })
  }

  public getDataConfirmation(cnpj?: string): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/concessionarias/integracao?Cnpj=${cnpj}`, { observe: 'response' })
  }

  public credenciarManual(razaoSocial: string, cnpj: string, email: string, regiao: string, codigo: number, tipo: number): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/concessionarias/credenciar-manual`, { razaoSocial, cnpj, email, regiao, codigo, tipo })
  }

  public getCanalRelecionamento() {
    return this.http.get<any>(`${this.urlApi}/v1/concessionarias/canal-relacionamento`)
  }

  public sendCode(obj: any, token: string): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/clientes/validar-sms/confirmar`, { ...obj, token })
  }

  public validateSms(obj: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/clientes/validar-sms`, { ...obj })
  }

  public getEstadosCidadesEntrega() {
    return this.http.get<any>(`${this.urlApi}/v1/concessionarias/endereco-entrega`)
  }

  public getConcessionariaEntrega(estado: string, cidade: string,): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/concessionarias/entrega?Estado=${estado}&Cidade=${cidade}`, { observe: 'response' })
  }

  public postContratoB2c(proposal?: IProposal): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.urlApi}/v1/contratos/b2c`, proposal, { observe: 'response' });
  }

  public integrationMtcode(): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/produtos/integracao-mtcode`, {}, { observe: 'response' })
  }

  public putRelationshipChannel(obj: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/v1/contratos/canal-relacionamento`, obj, { observe: 'response' })
  }

  public getFinancialMovement(DataInicio: string, DataFinal: string, TipoRelatorio: string, FormatoArquivo: string) {
    return this.http.get(`${this.urlApi}/v1/relatorios/conciliacao-movimentacao-financeira?DataInicio=${DataInicio}&DataFim=${DataFinal}&Tipo=${TipoRelatorio}&FormatoArquivo=${FormatoArquivo}`, { responseType: 'blob', observe: 'response' });
  }

  public getFinancialGroup(): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/concessionarias/grupo-financeiro`, { observe: 'response' })
  }

  public getChangeReport(dataInicio: string, dataFim: string, idsEmpresas?: Array<number>, idsCanalRelacionamento?: Array<number>, idsStatusOrigem?: Array<number>, idsStatusDestino?: Array<number>, canalOriginacao?: number, contrato?: string): Observable<any> {
    let query = '?';
    if (contrato) {
      query = `NumeroContrato=${contrato}`
    } else {
      query = `DataInicial=${dataInicio}&DataFinal=${dataFim}`
      if (idsEmpresas && idsEmpresas.length > 0) query += `&CanalVenda=${idsEmpresas?.join('&CanalVenda=')}`
      if (idsCanalRelacionamento && idsCanalRelacionamento.length > 0) query += `&CanalRelacionamento=${idsCanalRelacionamento?.join('&CanalRelacionamento=')}`
      if (canalOriginacao) query += `&CanalOriginacao=${canalOriginacao}`
    }
    if (idsStatusOrigem) query += `&StatusOrigem=${idsStatusOrigem?.join('&StatusOrigem=')}`
    if (idsStatusDestino) query += `&StatusFinal=${idsStatusDestino?.join('&StatusFinal=')}`

    return this.http.get<any>(
      `${this.urlApi}/v1/relatorios/mudanca-status?${query}`, { observe: 'response' })
  }

  public deleteMinutaTemplate(idTemplate: any): Observable<any> {
    return this.http.delete<any>(`${this.urlApi}/v1/template-minuta/${idTemplate}`)
  }

  public getAccessProfile(idPerfilAcesso?: number): Observable<any> {
    const url = idPerfilAcesso
        ? `${this.urlApi}/v1/perfil-acesso/permissao-acesso?IdPerfilAcesso=${idPerfilAcesso}`
        : `${this.urlApi}/v1/perfil-acesso/permissao-acesso`;

    return this.http.get<any>(url, { observe: 'response' });
}

public getListProfile(Nome?: string, Identificador?: string[]): Observable<any> {
  let url = `${this.urlApi}/v1/perfil-acesso`;

  if (Nome || (Identificador && Identificador.length > 0)) {
      url += '?';
      const params: string[] = [];

      if (Nome) {
          params.push(`Nome=${encodeURIComponent(Nome)}`);
      }
      if (Identificador && Identificador.length > 0) {
          Identificador.forEach(id => {
              params.push(`Identificador=${encodeURIComponent(id)}`);
          });
      }

      url += params.join('&');
  }

  return this.http.get<any>(url, { observe: 'response' });
}

  public postCreateProfile(obj: any): Observable<any> {
    return this.http.post<any>(`${this.urlApi}/v1/perfil-acesso`, {...obj }, { observe: 'response' })
  }

  public deleteProfile(idPerfilAcesso: number): Observable<HttpResponse<any>> {
    return this.http.request<any>('delete', `${this.urlApi}/v1/perfil-acesso`, {
        body: { idPerfilAcesso },
        observe: 'response'
    });
  }

  public editProfile(obj: any): Observable<any> {
    return this.http.put<any>(`${this.urlApi}/v1/perfil-acesso`, obj, { observe: 'response' })
  }

  public getExtensionInfo(IdContrato: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/prorrogacao?IdContrato=${IdContrato}`, { observe: 'response' })
  }

  public extendPlan(extensionData: any) {
    return this.http.post<any>(`${this.urlApi}/v1/prorrogacao`, extensionData)
  }

  public getConcessionariasContrato(IdContrato: string) {
    return this.http.get<any>(`${this.urlApi}/v1/concessionarias/contrato?IdContrato=${IdContrato}`);
  }

  public getConcessionariasIntegracaoB2b2c(search?: string, page?: number, pageSize?: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v2/usuarios/concessionarias?Filtro=${search}&PageNumber=${page}&PageSize=${pageSize}`)
  }

  public getConcessionariasHashcode(hashcode?: string): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/v1/concessionarias/hashcode?HashCode=${hashcode}`)
  }

  public getTermoProrrogacao(idContrato?: number, assinado?: boolean): Observable<any> {
    return this.http.get(`${this.urlApi}/v1/prorrogacao/termo?IdContrato=${idContrato}&Assinado=${assinado}`, { observe: 'response' })
  }

  public convertHtmlToPdf(htmlContent: string): Observable<any> {
    const body = { template: htmlContent };
    return this.http.post<any>(`${this.urlApi}/v1/template-minuta/preview`, body);
  }
}
