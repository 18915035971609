<div *ngIf="showSendCard" class="button-back">
  <yfs-link-back text="Voltar para verificação dos dados da proposta" (click)="back()"></yfs-link-back>
</div>
<div class="container" *ngIf="loggedIn && assinaturaEletronicaHabilitada">
 <div class="divisor-content steps">
    <span class="points"><img src="assets/svg/step-1.svg" alt="icone do primeiro passo"> ..... </span>
    <span *ngIf="!fileSizeError && showSendCard"><img src="assets/svg/step-2.svg" alt="icone de segundo passo"></span>
    <span *ngIf="fileSizeError"><img src="assets/svg/step-2-error.svg" alt="icone de segundo passo com erro"></span>
    <span *ngIf="!showSendCard && !fileSizeError"><img src="assets/svg/step-2-check.svg" alt="icone de segundo passo com sucesso"></span>
  </div>
  <div *ngIf="!showSendCard" class="message">
    <span>Termo enviado e aguardando assinatura.</span>
  </div>
    <mat-card *ngIf="showSendCard" class="card">
        <mat-card-content class="card-content">
           <div class="recipient-content">
            <span>Escolha como quer receber o termo de adesão:</span>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="formaEnvio">
              <mat-radio-button style="margin-left: -10px;" value="Email">E-mail</mat-radio-button>
              <mat-radio-button style="margin-left: 8px;" value="Whatsapp">Whatsapp</mat-radio-button>
              <mat-radio-button *ngIf="showSms" style="margin-left: 8px;" value="Sms">SMS</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-form-field *ngIf="formaEnvio === 'Email'" class="input" appearance="fill">
            <mat-label>E-mail</mat-label>
            <input data-test="input-email" [formControl]="email" matInput maxlength="50">
          </mat-form-field>
          <mat-form-field *ngIf="formaEnvio === 'Whatsapp'" class="input" appearance="fill">
            <mat-label>Whatsapp</mat-label>
            <input data-test="input-phone-cell-ddd"  [formControl]="celular" mask="(00) 0 0000-0000" matInput>
          </mat-form-field>
          <mat-form-field *ngIf="formaEnvio === 'Sms'" class="input" appearance="fill">
            <mat-label>SMS</mat-label>
            <input data-test="input-phone-cell-ddd"  [formControl]="sms" mask="(00) 0 0000-0000" matInput>
          </mat-form-field>
          <div style="margin-bottom: 24px;">
            <div class="alert-message">
              <span class="icon-alert-message"><mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
          fontIcon="info"></mat-icon></span>
              <span class="title-alert-message">Certifique-se que os dados estejam corretos, pois não poderão ser alterados após a confirmação.</span>
            </div>
        </div>
        <div class="button">
          <yfs-button data-test="button-regulamento" label="CONFIRMAR FORMA DE ENVIO" icon="check_circle" (click)="verifyShipping()" type="primary" state="enabled"></yfs-button>
        </div>
        </mat-card-content>
    </mat-card>
    <div *ngIf="!showSendCard" class="verify-message">
      <span class="verify-title-message">Verifique o e-mail, whatsapp ou sms escolhido para envio e <b>assine o termo de adesão</b> para ter acesso à todas as informações do seu Liberacred.</span>
    </div>
    <div *ngIf="!showSendCard" style="margin-bottom: 40px;">
      <div class="card-signature-message">
        <span class="icon-signature-message"><mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
    fontIcon="info"></mat-icon></span>
        <span class="signature-message"><b>Assine o termo até {{ dataExpiracaoTermoAdesao | date : 'dd/MM/yyyy HH:mm' }}</b> para efetivar o contrato. A não assinatura do mesmo resultará no cancelamento do plano.</span>
      </div>
  </div>
    <div *ngIf="!showSendCard">
      <div class="card-message">
        <span class="icon-message"><mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
    fontIcon="info"></mat-icon></span>
        <span class="title-message">Foi enviado para o e-mail cadastrado na compra, instruções de primeiro acesso a plataforma. Caso não tenha recebido, verifique na caixa de spam.</span>
      </div>
  </div>
  <div *ngIf="!showSendCard" class="btn-download-regulamento">
    <yfs-button data-test="button-regulamento" label="REGULAMENTO" class="download" (click)="gerarRegulamento()" size="small" type="primary" state="enabled" icon="download"></yfs-button>
  </div>
</div>
<div class="old-container" *ngIf="loggedIn && !assinaturaEletronicaHabilitada">
    <div class="divisor-content-steps">
       <span class="points"><img src="assets/svg/step-1.svg" alt="icone do primeiro passo"> ..... </span>
       <span *ngIf="!fileSizeError && !success"><img src="assets/svg/step-2.svg" alt="icone de segundo passo"></span>
       <span *ngIf="fileSizeError"><img src="assets/svg/step-2-error.svg" alt="icone de segundo passo com erro"></span>
       <span *ngIf="success && !fileSizeError"><img src="assets/svg/step-2-check.svg" alt="icone de segundo passo com sucesso"></span>
     </div>
     <div *ngIf="!success" class="message">
       <span>Cadastro realizado com sucesso!</span>
       <span>Agora baixe os arquivos e faça upload do termo de adesão assinado abaixo:</span>
     </div>
     <div *ngIf="success" class="message">
       <span>Pronto! O plano foi contratado com sucesso.</span>
     </div>
     <yfs-button data-test="button-home" *ngIf="success" class="btn-return" (click)="goHome()" label="VOLTAR PARA O INÍCIO" type="primary" state="enabled"></yfs-button>
     <div *ngIf="!success" class="divisor-content btn-download">
       <yfs-button data-test="button-regulamento" label="REGULAMENTO" class="download" (click)="gerarRegulamento()" size="small" type="primary" state="enabled" icon="download"></yfs-button>
       <yfs-button data-test="button-minuta" label="TERMO DE ADESÃO" class="download" (click)="gerarMinuta()" size="small" type="primary" state="enabled" icon="download"></yfs-button>
       <yfs-button data-test="button-boleto"[label]="isBoletoBeingGenerated ? 'GERANDO BOLETO' : 'BOLETO'" class="download" (click)="gerarBoleto()" type="primary" size="small" [loadingIndicator]="isBoletoBeingGenerated ? true : false" [state]="isBoletoBeingGenerated ? 'disabled' : 'enabled'" icon="download"></yfs-button>
     </div>
       <mat-card *ngIf="!success" class="card normal-content">
           <mat-card-content class="card-content">
              <span class="title">Insira aqui o arquivo com o termo de adesão assinado:</span>
               <span class="subtitle">Somente um arquivo em PDF de até 5mb.</span>
               <yfs-button label="ENVIAR" state="enabled" type="primary" data-test="button-send" class="btn-send" (click)="onSend()"></yfs-button>
               <a (click)="fileInput.click()">Escolher o arquivo</a>
               <input hidden data-test="input-file" (change)="onFileSelected($event)" #fileInput type="file" accept=".pdf" id="file">
               <div>{{fileName}}<span *ngIf="fileName" (click)="onRemoveFile()"><img class="trash-icon icon-remove" src="assets/svg/trash.svg" alt="icone de deletar"></span></div>
               <span data-test="error-message-file-size" *ngIf="fileSizeError" class="error-message"><img src="assets/svg/atention.svg" alt="icone de atenção"> O arquivo ultrapassou o tamanho máximo de 5MB</span>
           </mat-card-content>
       </mat-card>
       <mat-card class="card-message-content">
         <mat-card-content class="card-message">
           <span class="icon-message"><mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
       fontIcon="info"></mat-icon></span>
           <span class="title-message">Foi enviado para o e-mail cadastrado na compra, instruções de primeiro acesso a plataforma. Caso não tenha recebido, verifique na caixa de spam.</span>
         </mat-card-content>
     </mat-card>
</div>
<div class="container" *ngIf="!loggedIn">
      <div class="divisor-content steps">
         <span class="points"><img src="assets/svg/step-1.svg" alt="icone do primeiro passo"> ..... </span>
         <span *ngIf="!fileSizeError && showSendCard"><img src="assets/svg/step-2.svg" alt="icone de segundo passo"></span>
         <span *ngIf="fileSizeError"><img src="assets/svg/step-2-error.svg" alt="icone de segundo passo com erro"></span>
         <span *ngIf="!showSendCard && !fileSizeError"><img src="assets/svg/step-2-check.svg" alt="icone de segundo passo com sucesso"></span>
       </div>
       <div *ngIf="!showSendCard" class="message">
         <span>Termo enviado e aguardando assinatura.</span>
       </div>
         <mat-card *ngIf="showSendCard" class="card">
             <mat-card-content class="card-content">
                <div class="recipient-content">
                 <span>Escolha como quer receber o termo de adesão:</span>
                 <mat-radio-group aria-label="Select an option" [(ngModel)]="formaEnvio">
                   <mat-radio-button style="margin-left: -10px;" value="Email">E-mail</mat-radio-button>
                   <mat-radio-button style="margin-left: 8px;" value="Whatsapp">Whatsapp</mat-radio-button>
                   <mat-radio-button *ngIf="showSms" style="margin-left: 8px;" value="Sms">SMS</mat-radio-button>
                 </mat-radio-group>
               </div>
               <mat-form-field *ngIf="formaEnvio === 'Email'" class="input" appearance="fill">
                 <mat-label>E-mail</mat-label>
                 <input data-test="input-email" [formControl]="email" matInput maxlength="50">
               </mat-form-field>
               <mat-form-field *ngIf="formaEnvio === 'Whatsapp'" class="input" appearance="fill">
                 <mat-label>Whatsapp</mat-label>
                 <input data-test="input-phone-cell-ddd"  [formControl]="celular" mask="(00) 0 0000-0000" matInput>
               </mat-form-field>
               <mat-form-field *ngIf="formaEnvio === 'Sms'" class="input" appearance="fill">
                 <mat-label>SMS</mat-label>
                 <input data-test="input-phone-cell-ddd"  [formControl]="sms" mask="(00) 0 0000-0000" matInput>
               </mat-form-field>
               <div style="margin-bottom: 24px;">
                 <div class="alert-message">
                   <span class="icon-alert-message"><mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
               fontIcon="info"></mat-icon></span>
                   <span class="title-alert-message">Certifique-se que os dados estejam corretos, pois não poderão ser alterados após a confirmação.</span>
                 </div>
             </div>
             <div class="button">
               <yfs-button data-test="button-regulamento" class="button" label="CONFIRMAR FORMA DE ENVIO" icon="check_circle" (click)="verifyShipping()" [fullWidth]="isFullWidth" type="primary" state="enabled"></yfs-button>
             </div>
             </mat-card-content>
         </mat-card>
         <div *ngIf="!showSendCard" class="verify-message">
           <span class="verify-title-message">Verifique o e-mail, whatsapp ou sms escolhido para envio e <b>assine o termo de adesão</b> para ter acesso à todas as informações do seu Liberacred.</span>
         </div>
         <div *ngIf="!showSendCard" style="margin-bottom: 40px;">
           <div class="card-signature-message">
             <span class="icon-signature-message"><mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
         fontIcon="info"></mat-icon></span>
             <span class="signature-message"><b>Assine o termo até {{ dataExpiracaoTermoAdesao | date : 'dd/MM/yyyy HH:mm' }}</b> para efetivar o contrato. A não assinatura do mesmo resultará no cancelamento do plano.</span>
           </div>
       </div>
         <div *ngIf="!showSendCard" class="card-message-content">
           <div class="card-message">
             <span class="icon-message"><mat-icon aria-hidden="false" aria-label="Ícone de informação" class="material-symbols"
         fontIcon="info"></mat-icon></span>
             <span class="title-message">Foi enviado para o e-mail cadastrado na compra, instruções de primeiro acesso a plataforma. Caso não tenha recebido, verifique na caixa de spam.</span>
           </div>
       </div>
       <div *ngIf="!showSendCard" class="btn-download-regulamento">
         <yfs-button data-test="button-regulamento" label="REGULAMENTO" class="download button" (click)="gerarRegulamento()" size="small" [fullWidth]="isFullWidth" type="primary" state="enabled" icon="download"></yfs-button>
       </div>
</div>
<yfs-loading-spinner *ngIf="isLoading"></yfs-loading-spinner>
